import React from 'react';
import { Box, H1 } from 'components/common';
import CheckCircleSvg from 'assets/icons/CheckCircleSvg';
import ExclamationSvg from 'assets/icons/ExclamationSvg';
import ExclamationCircleSvg from 'assets/icons/ExclamationCircleSvg';
import { romantic, green, red } from 'styles/system';

type Type = 'success' | 'warning' | 'error';
interface Props {
  type: Type;
  title: string;
  children: React.ReactNode;
}

const getIcon = (type: Type) => {
  switch (type) {
    case 'success':
      return (
        <Box color={green[600]}>
          <CheckCircleSvg width={100} height={100} strokeWidth={1.5} />
        </Box>
      );
    case 'warning':
      return (
        <Box color={romantic[600]}>
          <ExclamationSvg width={100} height={100} strokeWidth={1.5} />
        </Box>
      );
    default:
      return (
        <Box color={red[600]}>
          <ExclamationCircleSvg width={100} height={100} strokeWidth={1.5} />
        </Box>
      );
  }
};

const ModalContentTemplate = ({ title, type, children }: Props) => {
  return (
    <Box margin="30px" textAlign="center">
      {getIcon(type)}
      <H1 mt={20}>{title}</H1>
      <Box mt={25}>{children}</Box>
    </Box>
  );
};

export default ModalContentTemplate;
