import styled from '@emotion/styled';
import { Theme } from 'styles/system';

export type Props = {
  mobile?: boolean;
  scrolled?: boolean;
};

const Container = styled.div<Props, Theme>`
  background-color: ${({ scrolled, theme }) =>
    scrolled ? theme.colors.white : 'transparent'};
  width: 100%;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: ${({ mobile }) =>
    mobile ? 'space-between' : 'space-around'};
  position: fixed;
  padding: 0 20px;
  top: 0;
  z-index: 1000;
  box-shadow: ${({ theme, scrolled }) =>
    scrolled ? theme.shadows.lg : theme.shadows.none};
  transition: all 0.5s ease-out;
  transition-property: box-shadow, background-color;
`;

export default Container;
