import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider as EmotionProvider } from 'emotion-theming';
import { defaultTheme, recuroTheme, secondaryTheme } from 'styles/system';
import GlobalStyle from 'styles/GlobalStyle';
import { RootState } from 'redux/rootReducer';

type Props = {
  children: React.ReactNode;
};

const themes = {
  primary: defaultTheme,
  secondary: secondaryTheme,
  recuro: recuroTheme,
};

function ThemeProvider({ children }: Props) {
  const theme = useSelector((state: RootState) => state.app.theme);
  return (
    <EmotionProvider theme={themes[theme]}>
      <>
        {children}
        <GlobalStyle />
      </>
    </EmotionProvider>
  );
}

export default ThemeProvider;
