import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = HTMLAttributes<HTMLButtonElement>;

const CopyButton = styled.button<Props, Theme>`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
  margin-top: 28px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary[400]};
  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.secondary[500]};
  }
  &:active:enabled {
    background-color: ${({ theme }) => theme.colors.secondary[800]};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray[400]};
    cursor: not-allowed;
  }
`;

export default CopyButton;
