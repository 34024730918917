import React from 'react';
import CopyForm, { Field } from 'components/common/CopyForm/CopyForm';
import { HimAndHair } from 'types/patient';
import { toMMDDYYYY } from 'utils/date';
import { getHHExpDate } from './utils';

type Props = {
  data: HimAndHair;
};

function HHPersonalInformation({ data }: Props) {
  const [expMonth, expYear] = getHHExpDate(data);
  const fields: Field[] = [
    { id: 1, label: 'First name', value: data.first_name },
    { id: 2, label: 'Middle name', value: data.middle_name },
    { id: 3, label: 'Last name', value: data.last_name },
    { id: 4, label: 'Phone number', value: data.phone_number },
    { id: 5, label: 'Birthdate', value: toMMDDYYYY(data.birthdate) },
    { id: 6, label: 'Email', value: data.email },
    { id: 7, label: 'Payment method', value: data.payment_method },
    { id: 8, label: 'Name on card', value: data.name_on_card },
    { id: 9, label: 'Credit card number', value: data.credit_card_number },
    {
      id: 10,
      label: 'Exp. month/year',
      value: `${expMonth}/${expYear?.substring(2, 4)}`,
    },
    {
      id: 11,
      label: 'Credit card security code',
      value: data.credit_card_security_code,
    },
  ];
  return (
    <div>
      <CopyForm fields={fields} />
    </div>
  );
}

export default HHPersonalInformation;
