import { get, isNaN, isNil } from 'lodash';

export const getExpDate = (data: unknown) => {
  const month = parseInt(get(data, 'credit_card_expiration[month]'), 10);
  const year = parseInt(get(data, 'credit_card_expiration[year]'), 10);
  let expMonth;
  let expYear;
  if (!isNaN(month)) {
    expMonth = month > 0 ? (month < 10 ? `0${month}` : month?.toString()) : '';
  }
  if (!isNaN(year)) {
    expYear = year.toString();
  }
  return [expMonth, expYear];
};

export const getHHExpDate = (data: unknown) => {
  const month = parseInt(get(data, 'cc_expiration[month]'), 10);
  const year = parseInt(get(data, 'cc_expiration[year]'), 10);
  let expMonth;
  let expYear;
  if (!isNaN(month)) {
    expMonth = month > 0 ? (month < 10 ? `0${month}` : month?.toString()) : '';
  }
  if (!isNaN(year)) {
    expYear = year.toString();
  }
  return [expMonth, expYear];
};

export const getTextFromBool = (
  bool: boolean | undefined
): string | undefined => {
  if (isNil(bool)) {
    return undefined;
  }
  return bool ? 'Yes' : 'No';
};
