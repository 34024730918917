import React from 'react';
import { Modal as ResponsiveModal, ModalProps } from 'react-responsive-modal';
import { defaultTheme, shadows } from 'styles/system';
import { cloneDeep, merge } from 'lodash';
import Close from 'assets/icons/Close';

const ModalStyles = {
  modal: {
    maxWidth: '768px',
    background: defaultTheme.colors.white,
    boxShadow: shadows.xl,
    borderRadius: '12px',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0,0,0,0.3)',
  },
  closeButton: {
    outline: 0,
  },
};

const Modal = ({ children, styles, ...rest }: ModalProps) => {
  const overwrittenStyles = merge(cloneDeep(ModalStyles), styles);

  return (
    <ResponsiveModal
      {...rest}
      closeIcon={<Close width={24} height={24} />}
      styles={overwrittenStyles}
      focusTrapped
    >
      {children}
    </ResponsiveModal>
  );
};

export default Modal;
