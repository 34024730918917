import React from 'react';
import { Link, Container } from './components';

export type Props = {
  children: React.ReactNode;
  closeModal?: () => void;
  mobile?: boolean;
};

function Nav({ children, closeModal, mobile }: Props) {
  const count = React.Children.count(children);
  return (
    <Container>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as JSX.Element, {
          first: index === 0,
          last: index === count - 1,
          mobile,
          closeModal,
        })
      )}
    </Container>
  );
}

Nav.Link = Link;

Nav.defaultProps = {
  mobile: false,
  closeModal: () => {},
};

export default Nav;
