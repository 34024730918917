import styled from '@emotion/styled';
import { Theme } from 'styles/system';

export type Props = {
  checked?: boolean;
};

const Label = styled.label<Props, Theme>`
  display: flex;
  align-items: center;
  margin: 10px;
  .radio {
    cursor: pointer;
    border: 2px solid
      ${({ checked, theme }) =>
        checked ? theme.primaryColor : theme.colors.gray[400]};
    box-sizing: border-box;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    .dot {
      background: ${({ checked, theme }) =>
        checked ? theme.primaryColor : theme.colors.white};
      border-radius: 50%;
      height: 14px;
      width: 14px;
    }
  }
  &:focus-within {
    outline: 1px dashed;
  }
`;

export default Label;
