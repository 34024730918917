import React from 'react';
import styled from '@emotion/styled';
import CheckSvg from 'assets/icons/CheckSvg';
import { Theme } from 'styles/system';

type Props = {
  status?: 'pending' | 'active' | 'finished';
};

const Wrapper = styled.div<Props, Theme>`
  width: 36px;
  height: 36px;
  background-color: ${({ status, theme }) =>
    status === 'finished' ? theme.colors.primary[500] : 'inherit'};
  border-width: 2px;
  border-style: solid;
  border-color: ${({ status, theme }) =>
    status === 'active' || status === 'finished'
      ? theme.colors.primary[500]
      : theme.colors.gray[400]};
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  .circle {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.primary[500]};
  }
  .check {
    width: 24px;
    height: 18px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

function StepIndicator({ status }: Props) {
  return (
    <Wrapper status={status}>
      {status === 'finished' && (
        <div className="check">
          <CheckSvg width={24} height={18} strokeWidth={2} />
        </div>
      )}
      {status === 'active' && <div className="circle" />}
    </Wrapper>
  );
}

StepIndicator.defaultProps = {
  status: 'pending',
};

export default StepIndicator;
