import React from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = {};

const Wrapper = styled.div<Props, Theme>`
  width: 100%;
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray[300]}`};
  margin: 32px 0;
`;

function Divider() {
  return <Wrapper />;
}

export default Divider;
