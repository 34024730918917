import React, { InputHTMLAttributes } from 'react';
import { Text } from 'components/common';
import { Label, Input } from './components';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  weight?: 'regular' | 'semi-bold';
};

function Radio({
  id,
  checked = false,
  label,
  weight = 'regular',
  onChange,
  ...rest
}: Props) {
  return (
    <>
      <Label htmlFor={id} checked={checked}>
        <div className="radio">
          <div className="dot" />
        </div>
        <Text weight={weight}>{label}</Text>
        <Input
          aria-checked={checked}
          id={id}
          checked={checked}
          onChange={onChange}
          type="radio"
          {...rest}
        />
      </Label>
    </>
  );
}

export default Radio;
