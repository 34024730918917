import {
  DBFields,
  PatientEntity,
  PrescriptionInformation,
  RegisteredPatient,
  UserFk,
  UserType,
} from './patient';
import { DbUser, Tokens } from './auth';

export type RegisterResponse = {
  response: RegisteredPatient;
  tokens: Tokens;
};

export type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type AuthResponse = {
  user: DbUser;
  tokens: Tokens;
};

type StatusCount = {
  count: number;
  today?: number;
};

export type PatientStatusResponse = {
  regular: StatusCount;
  contrave: StatusCount;
  docTalkGo: StatusCount;
  change: StatusCount;
  prescription: StatusCount;
  recuro: StatusCount;
  himandhair: StatusCount;
};

export type ChangeRequestStatusResponse = {
  regular: StatusCount;
  contrave: StatusCount;
  recuro: StatusCount;
};

export type PatientSearchResult = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  birthdate: string;
  type: UserType;
  created_at: string;
  seizures_bool: boolean;
  overnight_shipping: boolean;
  contrave_month_supply: number;
};

export type HHPatientSearchResult = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  birthdate: string;
  created_at: string;
  is_processed: boolean;
};

export type PatientSearchResponse = {
  results: PatientSearchResult[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
};

export type HHPatientSearchResponse = {
  results: HHPatientSearchResult[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
};

type EntityType =
  | 'Personal Information'
  | 'Contact Information'
  | 'Health Information'
  | 'Dependent Information'
  | 'Insurance Information'
  | 'Prescription Information'
  | 'Payment Information'
  | 'Automatic Refill';

export type HistoryEntity = PatientEntity & { entity?: EntityType };

export type HistoryTableResponse = Array<HistoryEntity>;

export enum RequestStatus {
  notStarted = 'notStarted',
  pending = 'pending',
  success = 'success',
  failed = 'failed',
}

export type PatientChangesResponse = {
  changes: RegisteredPatient;
};

export type PrescriptionResponse = PatientSearchResult & {
  prescriptions: Array<PrescriptionInformation & DBFields & UserFk>;
};
export type PrescriptionsResponse = Array<PrescriptionResponse>;
