import React from 'react';
import { isEmpty } from 'lodash';
import { FakeField, CopyField } from 'components/common';
import {
  AutomaticRefill,
  DBFields,
  PaymentInformation as PaymentInfo,
} from 'types/patient';
import { toMMDDYYYY } from 'utils/date';
import { getExpDate, getTextFromBool } from './utils';
import EmptyForm from './EmptyForm';

type Props = {
  patientType?: 'contrave' | 'regular' | 'recuro' | 'himandhair';
  data?: PaymentInfo;
  refill?: AutomaticRefill & DBFields;
  zipCode?: string | undefined;
};

function PaymentInformation({ data, refill, patientType, zipCode }: Props) {
  const [expMonth, expYear] = getExpDate(data);
  return (
    <div className="form">
      <div className="fields">
        {!isEmpty(data) && (
          <>
            <FakeField label="Payment method" value={data?.payment_method} />
            <CopyField
              label="Credit card number"
              value={data?.credit_card_number}
            />
            <CopyField
              label="Exp. month/year"
              value={`${expMonth}/${expYear?.substring(2, 4)}`}
              ccExpDate
            />
            <CopyField
              label="Security code"
              value={data?.credit_card_security_code?.toString()}
            />
            <CopyField label="Full name" value={data?.name_on_card} />
            {zipCode && <CopyField label="Zip code" value={zipCode} />}
          </>
        )}
        {patientType === 'contrave' && (
          <>
            {!isEmpty(data) && (
              <>
                <CopyField
                  label="Overnight shipping?"
                  value={getTextFromBool(data?.overnight_shipping)}
                />
                <CopyField
                  label="90-day refill?"
                  value={data?.contrave_month_supply === 3 ? 'Yes' : 'No'}
                />
              </>
            )}
            {!isEmpty(refill) && (
              <>
                <CopyField
                  label="Automatic refill?"
                  value={getTextFromBool(refill?.enabled)}
                />
                {refill?.enabled && (
                  <>
                    <CopyField
                      label="Automatic refill approval signed on:"
                      value={toMMDDYYYY(refill?.created_at)}
                    />
                    <CopyField
                      label="Automatic refill signature"
                      value={refill?.name}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        {isEmpty(data) && isEmpty(refill) && <EmptyForm />}
      </div>
    </div>
  );
}

PaymentInformation.defaultProps = {
  patientType: 'regular',
  data: undefined,
  refill: undefined,
  zipCode: undefined,
};

export default PaymentInformation;
