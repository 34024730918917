import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import ChevronDown from 'assets/icons/ChevronDown';
import ChevronUp from 'assets/icons/ChevronUp';
import { Box, Text } from 'components/common';
import { Theme } from 'styles/system';

type TriggerProps = {
  title?: ReactNode;
  subtitle?: string;
  subtitleColor?: 'gray' | 'warning' | string;
  position?: 'first' | 'last' | 'middle';
  background?: 'default' | 'white' | 'gray';
};

const Container = styled.div<Omit<TriggerProps, 'title'>, Theme>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ background, theme }) =>
    background === 'gray'
      ? theme.colors.gray[50]
      : background === 'white'
      ? theme.colors.white
      : 'initial'};
  color: ${({ theme }) => theme.colors.gray[800]};
  padding: 16px;
  border-radius: ${({ position }) =>
    position === 'first'
      ? '8px 8px 0 0 '
      : position === 'last'
      ? '0 0 8px 8px'
      : '0'};
  .header {
    display: block;
  }
  .arrows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${({ theme }) => theme.colors.gray[500]};
    .down {
      margin-top: -8px;
    }
  }
`;

function Trigger({
  background,
  position,
  title,
  subtitle,
  subtitleColor,
}: TriggerProps) {
  return (
    <Container
      background={background}
      position={position}
      role="button"
      tabIndex={0}
    >
      <div className="header">
        <Text as="p" weight="semi-bold" size="xl">
          {title}
        </Text>
        <Text as="p" color={subtitleColor}>
          {subtitle}
        </Text>
      </div>
      <Box minWidth={18}>
        <div className="arrows">
          <div className="up">
            <ChevronUp width={20} height={16} strokeWidth={3} />
          </div>
          <div className="down">
            <ChevronDown width={20} height={16} strokeWidth={3} />
          </div>
        </div>
      </Box>
    </Container>
  );
}

Trigger.defaultProps = {
  title: '',
  subtitle: '',
  subtitleColor: 'gray',
  position: 'middle',
  background: 'default',
};

export default Trigger;
