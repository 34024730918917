import styled from '@emotion/styled';
import { Theme } from 'styles/system';

export type Props = {};

const Container = styled.div<Props, Theme>`
  align-items: flex-start;
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
`;

export default Container;
