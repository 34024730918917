import React from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

const SkipToContentAnchor = styled.a<{}, Theme>`
  background: ${({ theme }) => theme.primaryColor};
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  left: 0;
  padding: 10px 15px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 1000;

  &:focus {
    transform: translateY(0%);
  }
`;

export default function SkipToContent() {
  return (
    <SkipToContentAnchor href="#main">Skip to content</SkipToContentAnchor>
  );
}
