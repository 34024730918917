import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { UserType } from 'types/patient';
import { HHPatientSearchResult, PatientSearchResult } from 'types/response';
import { RootState } from './rootReducer';

export enum AdminPages {
  home = 'home',
  changes = 'changes',
  prescriptions = 'prescriptions',
  contraveLegacy = 'contraveLegacy',
}

export type TableType = 'new' | 'all' | 'changes';
export type TableUserType = UserType | 'contraveLegacy';
export type TableSearchBody = Partial<
  PatientSearchResult & { start_date: string; end_date: string }
>;

export type HHTableSearchBody = Partial<
  HHPatientSearchResult & { start_date: string; end_date: string }
>;

export type TableSearchQuery = {
  is_new?: boolean;
  changes?: boolean;
  page?: number;
  page_size?: number;
};

type TablesSearch = {
  [key in TableType]: {
    [key in TableUserType]?: TableSearchBody;
  };
};

type AdminTablesState = {
  tablesSearch: TablesSearch;
  lastUpdated: {
    [key in AdminPages]?: string;
  };
};

const adminTablesSelector = (state: RootState) => state.adminTables;

const lastUpdatedSelector = createSelector(
  adminTablesSelector,
  (tables) => tables.lastUpdated
);

const searchSelector = createSelector(
  adminTablesSelector,
  (tables) => tables.tablesSearch
);

const initialState: AdminTablesState = {
  tablesSearch: {
    new: {
      regular: { type: 'regular' },
      contrave: { type: 'contrave' },
      recuro: { type: 'recuro' },
      himandhair: { type: 'himandhair' },
    },
    all: {
      regular: {},
      // contrave: { type: 'contrave' },
      contraveLegacy: {},
    },
    changes: {
      regular: { type: 'regular' },
      contrave: { type: 'contrave' },
      recuro: { type: 'recuro' },
    },
  },
  lastUpdated: {},
};

const adminTablesSlice = createSlice({
  name: 'adminTables',
  initialState,
  reducers: {
    setLastUpdated(state, action: PayloadAction<{ page: AdminPages }>) {
      state.lastUpdated[action.payload.page] = moment().format();
    },
    setSearch(
      state,
      action: PayloadAction<{
        tableType: TableType;
        patientType: TableUserType;
        search: TableSearchBody;
      }>
    ) {
      const { tableType, patientType, search } = action.payload;
      state.tablesSearch[tableType][patientType] = search;
    },
    resetSearch(
      state,
      action: PayloadAction<{
        tableType: TableType;
        patientType: TableUserType;
      }>
    ) {
      const { tableType, patientType } = action.payload;
      const defaultSearch: TableSearchBody = {};

      state.tablesSearch[tableType][patientType] = defaultSearch;
    },
  },
});

const { actions, reducer } = adminTablesSlice;
export const { setLastUpdated, setSearch, resetSearch } = actions;
export { lastUpdatedSelector, searchSelector };
export default reducer;
