import { primaryFont, secondaryFont } from './typography';
import {
  chill,
  chambray,
  romantic,
  gray,
  black,
  white,
  red,
  green,
  yellow,
  purple,
} from './colors';
import { breakpoints } from './breakpoints';
import shadows from './shadows';

const themeColors = {
  primary: chill,
  secondary: chambray,
  accent: romantic,
  error: red,
  success: green,
  warning: yellow,
  black,
  gray,
  white,
};

const secondaryThemeColors = {
  primary: chambray,
  secondary: chill,
  accent: romantic,
  error: red,
  success: green,
  warning: yellow,
  black,
  gray,
  white,
};

const recuroThemeColors = {
  primary: purple,
  secondary: gray,
  accent: romantic,
  error: red,
  success: green,
  warning: yellow,
  black,
  gray,
  white,
};

export const defaultTheme = {
  breakpoints,
  disabled: gray[200],
  primaryColor: chill[500],
  primaryActiveColor: chill[700],
  primaryHoverColor: chill[400],
  primaryFont,
  secondaryFont,
  textColor: gray[800],
  textColorDisabled: gray[400], // disabled without bg
  textColorInverted: white,
  textColorOnPrimary: white,
  textColorOnDisabled: gray[500], // color on disabled bg
  colors: themeColors,
  status: {
    warningColor: yellow[500],
    warningHoverColor: yellow[100],
    warningActiveColor: yellow[600],
    errorColor: red[500],
    errorHoverColor: red[100],
    errorActiveColor: red[600],
    successColor: green[500],
    successHoverColor: green[100],
    successActiveColor: green[600],
  },
  shadows,
};

export type Theme = typeof defaultTheme;

export const secondaryTheme = {
  breakpoints,
  disabled: gray[200],
  primaryColor: chambray[500],
  primaryActiveColor: chambray[700],
  primaryHoverColor: chambray[400],
  primaryFont,
  secondaryFont,
  textColor: gray[800],
  textColorDisabled: gray[400], // disabled without bg
  textColorInverted: white,
  textColorOnPrimary: white,
  textColorOnDisabled: gray[500], // color on disabled bg
  colors: secondaryThemeColors,
  status: {
    warningColor: yellow[500],
    warningHoverColor: yellow[100],
    warningActiveColor: yellow[600],
    errorColor: red[500],
    errorHoverColor: red[100],
    errorActiveColor: red[600],
    successColor: green[500],
    successHoverColor: green[100],
    successActiveColor: green[600],
  },
  shadows,
};

export const recuroTheme = {
  breakpoints,
  disabled: gray[200],
  primaryColor: purple[500],
  primaryActiveColor: purple[700],
  primaryHoverColor: purple[400],
  primaryFont,
  secondaryFont,
  textColor: gray[800],
  textColorDisabled: gray[400], // disabled without bg
  textColorInverted: white,
  textColorOnPrimary: white,
  textColorOnDisabled: gray[500], // color on disabled bg
  colors: recuroThemeColors,
  status: {
    warningColor: yellow[500],
    warningHoverColor: yellow[100],
    warningActiveColor: yellow[600],
    errorColor: red[500],
    errorHoverColor: red[100],
    errorActiveColor: red[600],
    successColor: green[500],
    successHoverColor: green[100],
    successActiveColor: green[600],
  },
  shadows,
};

export type RecuroTheme = typeof recuroTheme;
