import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

function Close(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
}

Close.defaultProps = {
  width: 18,
  height: 16,
};

export default Close;
