import React from 'react';
import { Paragraph, RouterLink, Text } from 'components/common';
import routes from 'constants/routes';
import ModalContentTemplate from './ModalContentTemplate';

function ModalContentEmailTaken() {
  return (
    <ModalContentTemplate type="warning" title="Email already taken.">
      <Paragraph>Please try another one.</Paragraph>
      <Paragraph mt={20}>
        Are you a registered patient?{' '}
        <RouterLink to={routes.login.path}>
          <Text color="primary-dark">Sign in here.</Text>
        </RouterLink>{' '}
        <br />
        Did you forget your password?{' '}
        <RouterLink to={routes.forgotPassword.path}>
          <Text color="primary-dark">Reset your password here.</Text>
        </RouterLink>{' '}
      </Paragraph>
    </ModalContentTemplate>
  );
}

export default ModalContentEmailTaken;
