import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep, isEmpty } from 'lodash';
import { RegisteredPatient } from 'types/patient';

const getPatientInfo = createAsyncThunk('patient/info', async () => {
  const { data } = await axios.get<RegisteredPatient>('/v1/patients');
  return data;
});

type MemberDashboardState = RegisteredPatient & {
  isFetchingPatient?: boolean;
};

const initialState: MemberDashboardState = {
  user: {},
  personal_information: {},
  contact_information: {},
  health_information: {},
  prescription_information: {},
  payment_information: {},
  automatic_refill: {},
  isFetchingPatient: false,
};

const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setPersonalInformation(state, action) {
      state.personal_information = action.payload || {};
    },
    setContactInformation(state, action) {
      state.contact_information = action.payload || {};
    },
    setHealthInformation(state, action) {
      state.health_information = action.payload || {};
    },
    setDependentsInformation(state, action) {
      state.dependents_information = action.payload || [];
    },
    setInsuranceInformation(state, action) {
      state.insurance_information = action.payload || {};
    },
    setPrescriptionInformation(state, action) {
      state.prescription_information = action.payload || {};
    },
    setPaymentInformation(state, action) {
      state.payment_information = action.payload || {};
    },
    setRefillInformation(state, action) {
      state.automatic_refill = action.payload || {};
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPatientInfo.pending, (state) => {
      state.isFetchingPatient = true;
    });
    builder.addCase(getPatientInfo.fulfilled, (_, action) => {
      const newState = cloneDeep(initialState);
      if (!isEmpty(action.payload.user)) {
        newState.user = action.payload.user;
      }
      if (!isEmpty(action.payload.personal_information)) {
        newState.personal_information = action.payload.personal_information;
      }
      if (!isEmpty(action.payload.contact_information)) {
        newState.contact_information = action.payload.contact_information;
      }
      if (!isEmpty(action.payload.health_information)) {
        newState.health_information = action.payload.health_information;
      }
      if (!isEmpty(action.payload.dependents_information)) {
        newState.dependents_information = action.payload.dependents_information;
      }
      if (!isEmpty(action.payload.insurance_information)) {
        newState.insurance_information = action.payload.insurance_information;
      }
      if (!isEmpty(action.payload.prescription_information)) {
        newState.prescription_information =
          action.payload.prescription_information;
      }
      if (!isEmpty(action.payload.payment_information)) {
        newState.payment_information = action.payload.payment_information;
      }
      if (!isEmpty(action.payload.automatic_refill)) {
        newState.automatic_refill = action.payload.automatic_refill;
      }
      return newState;
    });
    builder.addCase(getPatientInfo.rejected, (state) => {
      state.isFetchingPatient = false;
    });
  },
});

const { actions, reducer } = memberSlice;
export const {
  setPersonalInformation,
  setContactInformation,
  setHealthInformation,
  setDependentsInformation,
  setInsuranceInformation,
  setPrescriptionInformation,
  setPaymentInformation,
  setRefillInformation,
  reset,
} = actions;
export { getPatientInfo };
export default reducer;
