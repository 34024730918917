import styled from '@emotion/styled';
import { ButtonHTMLAttributes } from 'react';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { Theme, typeScale } from 'styles/system';

export type Props = ButtonHTMLAttributes<HTMLButtonElement> &
  LayoutProps &
  SpaceProps & {
    color?: 'primary' | 'secondary' | 'tertiary';
    paddingSize: 'sm' | 'md' | 'lg';
    shadow: 'base' | 'md' | 'lg' | 'xl' | 'xxl' | 'inset' | 'none';
  };

const paddings = {
  sm: '12px 24px',
  md: '12px 48px',
  lg: '12px 64px',
};

const BaseButton = styled.button<Props, Theme>`
  border: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: ${typeScale.base};
  font-weight: 600;
  line-height: 24px;
  outline: inherit;
  padding: ${({ paddingSize }) => paddings[paddingSize]};
  transition: background-color 0.1s linear, color 0.1s linear,
    border 0.1s linear;
  box-shadow: ${({ shadow, theme }) => theme.shadows[shadow]};

  ${layout}
  ${space}

  &:disabled {
    cursor: not-allowed;
  }
`;

export default BaseButton;
