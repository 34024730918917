import React from 'react';
import Box from 'components/common/Box/Box';
import Text from 'components/common/Text/Text';
import SolidLocationMarker from 'assets/icons/SolidLocationMarker';
import SolidPhone from 'assets/icons/SolidPhone';
import SolidPrinter from 'assets/icons/SolidPrinter';
import SolidMail from 'assets/icons/SolidMail';
import SolidCalendar from 'assets/icons/SolidCalendar';
import ClipboardCopy from 'assets/icons/ClipboardCopy';

type Props = {
  children: string | React.ReactNode;
  icon: 'marker' | 'phone' | 'printer' | 'mail' | 'calendar' | 'copy';
  iconColor?:
    | 'default'
    | 'primary'
    | 'primary-dark'
    | 'white'
    | 'light-gray'
    | 'gray'
    | 'dark-gray';
  textColor?:
    | 'default'
    | 'primary'
    | 'primary-dark'
    | 'white'
    | 'light-gray'
    | 'gray'
    | 'dark-gray';
  textAlign?: 'center' | 'left' | 'right';
};

function IconText({ children, icon, iconColor, textColor, textAlign }: Props) {
  return (
    <Box display="flex" flexDirection="row">
      <Box mr="10px">
        <Text color={iconColor} lineHeight="25.6px">
          {icon === 'marker' && <SolidLocationMarker width={16} height={16} />}
          {icon === 'mail' && <SolidMail width={16} height={16} />}
          {icon === 'phone' && <SolidPhone width={16} height={16} />}
          {icon === 'printer' && <SolidPrinter width={16} height={16} />}
          {icon === 'calendar' && <SolidCalendar width={16} height={16} />}
          {icon === 'copy' && <ClipboardCopy width={16} height={16} />}
        </Text>
      </Box>
      <Text color={textColor} lineHeight="25.6px" textAlign={textAlign}>
        {children}
      </Text>
    </Box>
  );
}

IconText.defaultProps = {
  iconColor: 'default',
  textColor: 'default',
  textAlign: 'left',
};

export default IconText;
