import React, { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = InputHTMLAttributes<HTMLInputElement> &
  InputHTMLAttributes<HTMLTextAreaElement> & {
    focused?: boolean;
    hasPrepend?: boolean;
    multiLine?: boolean;
    status?: 'valid' | 'invalid';
  };

const Input = styled.input<Props, Theme>`
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ focused, status, theme }) =>
    `1px solid ${
      focused
        ? theme.primaryColor
        : status === 'valid'
        ? theme.colors.success[600]
        : status === 'invalid'
        ? theme.colors.error[500]
        : theme.colors.gray[400]
    }`};
  border-radius: 8px;
  height: 50px;
  min-width: 280px;
  @media (max-width: 360px) {
    min-width: 90%;
  }
  padding-left: 20px;
  padding-right: 20px;
  transition: border-color 0.5s ease-out;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.colors.gray[400]};
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.colors.gray[400]};
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.colors.gray[400]};
  }
  &:focus {
    border-left: ${({ hasPrepend }) => hasPrepend && 'none'};
    outline: none;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.textColorDisabled};
    cursor: not-allowed;
  }
`;

const TextArea = Input.withComponent('textarea');

function InputField({ multiLine, ...rest }: Props) {
  return (
    <>
      {multiLine ? (
        <TextArea rows={6} style={{ height: 'auto' }} {...rest} />
      ) : (
        <Input {...rest} />
      )}
    </>
  );
}

InputField.defaultProps = {
  focused: false,
  hasPrepend: false,
  multiLine: false,
  status: undefined,
};

export default InputField;
