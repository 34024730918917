import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    resetScroll();
    resetFocus();
  }, [pathname]);

  return null;
}

function resetScroll() {
  window.scrollTo(0, 0);
}

function resetFocus() {
  document.getElementById('root')?.focus();
}
