import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = HTMLAttributes<HTMLSpanElement> & {
  focused?: boolean;
  status?: 'valid' | 'invalid';
  variant?: 'default' | 'inverse';
};

const InputPrepend = styled.span<Props, Theme>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, variant }) =>
    variant === 'inverse' ? theme.colors.gray[400] : theme.colors.white};
  color: ${({ theme, variant }) =>
    variant === 'inverse' ? theme.colors.white : theme.colors.gray[400]};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 50px;
  width: 40px;
  border: ${({ focused, status, theme }) =>
    `1px solid ${
      focused
        ? theme.primaryColor
        : status === 'valid'
        ? theme.colors.success[600]
        : status === 'invalid'
        ? theme.colors.error[500]
        : theme.colors.gray[400]
    }`};
  border-right: none;
`;

export default InputPrepend;
