import React from 'react';
import Text, { Props as TextProps } from '../Text/Text';

export type Props = TextProps & {
  children: React.ReactNode;
};

function Paragraph({ children, color, ...rest }: Props) {
  return (
    <Text as="p" mb="8px" color={color} lineHeight="26px" size="base" {...rest}>
      {children}
    </Text>
  );
}

Paragraph.defaultProps = {
  color: 'default',
};

export default Paragraph;
