import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import routes from 'constants/routes';
import { authSelector, refreshTokens } from 'redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  ScrollToTop,
  PrivateRoute,
  GoogleTagManagerPageViewRecorder,
  SkipToContent,
} from './common';

const MainHome = lazy(() => import('./MainHome'));
const MainMemberDashboard = lazy(() => import('./MainMemberDashboard'));
const MainAdminDashboard = lazy(() => import('./MainAdminDashboard'));

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        const { data } = await axios.get('/v1/auth/csrf-token');
        axios.defaults.headers.post['X-CSRF-Token'] = data.csrfToken;
      } catch (error) {
        // can't fetch csrf
      }
    };

    const initAuth = async () => {
      await getCsrfToken();
      dispatch(refreshTokens());
    };

    initAuth();
  }, [dispatch]);

  const auth = useSelector(authSelector);

  if (auth.isPending) {
    return null; // TODO: Add loading GIF for first page load.
  }

  return (
    <BrowserRouter>
      <SkipToContent />
      <GoogleTagManagerPageViewRecorder />
      <Suspense fallback={null}>
        <Switch>
          <PrivateRoute path={routes.memberHome.path} admitedRoles={['user']}>
            <MainMemberDashboard />
          </PrivateRoute>
          <PrivateRoute path={routes.adminHome.path} admitedRoles={['admin']}>
            <MainAdminDashboard />
          </PrivateRoute>
          <Route to={routes.home.path} exact>
            <MainHome />
          </Route>
        </Switch>
      </Suspense>
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
