import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/forms';

const useInputChange = <T extends {}>(
  arg: T
): [T, (e: Event) => void, Dispatch<SetStateAction<T>>] => {
  const [input, setInput] = useState<T>(arg);

  // eslint-disable-next-line no-useless-escape
  const allowedChars = /^[a-zA-Z0-9\s/]*$/;

  const handleInputChange = (e: Event) => {
    if (e.target.name === 'credit_card_number') {
      const { value } = e.target;
      const filteredValue = value.replace(/\D/g, ''); // Remove any non-digit characters
      setInput({
        ...input,
        [e.target.name]: filteredValue,
      });
    } else if (e.target.name === 'rx_group' || e.target.name === 'member_id') {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    } else if (allowedChars.test(e.target.value)) {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === 'email') {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    }
  };
  return [input, handleInputChange, setInput];
};

export { useInputChange as default };
