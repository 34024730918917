import React from 'react';
import styled from '@emotion/styled';
import { flexbox, FlexboxProps } from 'styled-system';
import { Theme } from 'styles/system';
import Item from './components/Item';

type Props = {
  children: React.ReactNode;
  direction?: 'vertical' | 'horizontal';
  variant?: 'gray' | 'primary';
} & Omit<FlexboxProps, 'flex-direction' | 'justify-content'>;

const ListContainer = styled.ul<Props, Theme>`
  list-style-type: none;
  color: ${({ theme }) => theme.colors.gray[700]};
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'horizontal' ? 'row' : 'column'};
  justify-content: center;
  text-align: left;
  ${({ variant }) => (variant === 'primary' ? 'padding-left: 0px' : '')};
  ${flexbox};
`;

function List({ children, direction, variant, ...rest }: Props) {
  return (
    <ListContainer direction={direction} variant={variant} {...rest}>
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, { variant })
          : null
      )}
    </ListContainer>
  );
}

List.Item = Item;

List.defaultProps = {
  direction: 'vertical',
  variant: 'gray',
};

export default List;
