export const regularSteps = [
  {
    id: 1,
    name: 'ACCOUNT VERIFIED',
    description: 'Provide the email and password to login to your account.',
  },
  {
    id: 2,
    name: 'TYPE OF PATIENT',
    description: 'Are you here regarding...',
  },
  {
    id: 3,
    name: 'PERSONAL INFORMATION',
    description: 'General information of yourself',
  },
  {
    id: 4,
    name: 'SHIPPING INFORMATION',
    description: 'The address where we will send your prescription',
  },
  {
    id: 5,
    name: 'HEALTH INFORMATION',
    description: 'Everything related to your current state of health',
  },
  {
    id: 6,
    name: 'DEPENDENT INFORMATION',
    description: 'Information about your dependents',
  },
  {
    id: 7,
    name: 'INSURANCE INFORMATION',
    description: 'Information of your insurance provider',
  },
  {
    id: 8,
    name: 'PRESCRIPTION INFORMATION',
    description: 'Transfer your prescription from another doctor or pharmacy',
  },
  {
    id: 9,
    name: 'PAYMENT INFORMATION',
    description: 'Add your credit card information',
  },
];

export const contraveSteps = [
  {
    id: 1,
    name: 'ACCOUNT VERIFIED',
    description: 'Provide the email and password to login to your account.',
  },
  {
    id: 2,
    name: 'TYPE OF PATIENT',
    description: 'Are you here regarding...',
  },
  {
    id: 3,
    name: 'PERSONAL INFORMATION',
    description: 'General information of yourself',
  },
  {
    id: 4,
    name: 'SHIPPING INFORMATION',
    description: 'The address where we will send your prescription',
  },
  {
    id: 5,
    name: 'HEALTH INFORMATION',
    description: 'Everything related to your current state of health',
  },
  {
    id: 6,
    name: 'PRESCRIPTION INFORMATION',
    description: 'Transfer your prescription from another doctor or pharmacy',
  },
  {
    id: 7,
    name: 'PAYMENT INFORMATION',
    description: 'Add your credit card information',
  },
];

export const recuroSteps = [
  {
    id: 1,
    name: 'ACCOUNT VERIFIED',
    description: 'Provide the email and password to login to your account.',
  },
  {
    id: 2,
    name: 'TYPE OF PATIENT',
    description: 'Are you here regarding...',
  },
  {
    id: 3,
    name: 'PERSONAL INFORMATION',
    description: 'General information of yourself',
  },
  {
    id: 4,
    name: 'SHIPPING INFORMATION',
    description: 'The address where we will send your prescription',
  },
  {
    id: 5,
    name: 'HEALTH INFORMATION',
    description: 'Everything related to your current state of health',
  },
  {
    id: 6,
    name: 'PRESCRIPTION INFORMATION',
    description: 'Transfer your prescription from another doctor or pharmacy',
  },
];
