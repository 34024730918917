import React from 'react';

type Props = {
  width?: number;
  height?: number;
  strokeWidth?: number;
};

function ChevronUp({ width, height, strokeWidth }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width={width}
      height={height}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M5 15l7-7 7 7"
      />
    </svg>
  );
}

ChevronUp.defaultProps = {
  width: 18,
  height: 16,
  strokeWidth: 2,
};

export default ChevronUp;
