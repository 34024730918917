import React from 'react';
import styled from '@emotion/styled';
import { Paragraph } from 'components/common';
import ExclamationSolid from 'assets/icons/ExclamationSolid';
import { Theme } from 'styles/system';

type Props = {};

const Container = styled.div<Props, Theme>`
  width: 100%;
  display: block;
  margin: 25px auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary[300]};
`;

function EmptyForm() {
  return (
    <div className="form">
      <div className="fields">
        <Container>
          <ExclamationSolid width={32} height={32} />
          <Paragraph size="lg"> No information</Paragraph>
        </Container>
      </div>
    </div>
  );
}

export default EmptyForm;
