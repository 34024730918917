import React from 'react';
import { Box } from 'components/common';
import texture from 'assets/patterns/TexturePattern.svg';
import { LayoutProps, PositionProps } from 'styled-system';

type Props = LayoutProps & PositionProps;

function Texture(props: Props) {
  return (
    <Box
      backgroundImage={`url(${texture})`}
      backgroundPosition="center center"
      position="absolute"
      {...props}
    />
  );
}

export default Texture;
