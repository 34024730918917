import React from 'react';
import { startCase } from 'lodash';
import CopyForm, { Field } from 'components/common/CopyForm/CopyForm';
import { DependentInformation as DependentInfo } from 'types/patient';
import { toMMDDYYYY } from 'utils/date';
import { getTextFromBool } from './utils';

type Props = {
  data: DependentInfo;
};

function HealthInformation({ data }: Props) {
  const gender = data.gender ? startCase(data.gender) : data.gender;
  const fields: Field[] = [
    {
      id: 1,
      label: 'Relationship',
      value: data.relationship,
    },
    {
      id: 2,
      label: 'First name',
      value: data.first_name,
    },
    {
      id: 3,
      label: 'Middle name',
      value: data.middle_name,
    },
    {
      id: 4,
      label: 'Last name',
      value: data.last_name,
    },
    {
      id: 5,
      label: 'Gender',
      value: gender,
    },
    { id: 6, label: 'Birthdate', value: toMMDDYYYY(data.birthdate) },
    {
      id: 7,
      label: 'Health conditions',
      value: data.health_conditions,
      type: 'textarea',
    },
    {
      id: 8,
      label: 'Drug allergies?',
      value: getTextFromBool(data.drug_allergies_bool),
    },
    {
      id: 9,
      label: 'Drug allergies',
      value: data.drug_allergies,
      type: 'textarea',
    },
    { id: 10, label: 'Medications', value: data.medications, type: 'textarea' },
  ];
  return (
    <div>
      <CopyForm fields={fields} />
    </div>
  );
}

export default HealthInformation;
