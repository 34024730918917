import {
  createAsyncThunk,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { Tokens } from 'types/auth';
import { AuthResponse } from 'types/response';
import { getExpirationTimeInMilli } from 'utils/date';
import { RootState } from './rootReducer';

export type AuthState = Partial<AuthResponse> & {
  isPending: boolean;
};

let tokenRefreshInterval: NodeJS.Timeout;

/** Selectors */
const authSelector = (state: RootState) => state.auth;
const isAuthenticatedSelector = createSelector(authSelector, (auth) => {
  const access = auth.tokens?.access;
  if (!access) {
    return false;
  }

  const tokenIsValid = getExpirationTimeInMilli(access.expires) >= 0;
  return tokenIsValid;
});

/** Thunks */
const refreshTokens = createAsyncThunk<AuthResponse>(
  'auth/refreshTokens',
  async (arg, thunkAPI) => {
    const response = await axios.get<AuthResponse>('/v1/auth/refresh-tokens');
    axios.defaults.headers.common.Authorization = `Bearer ${response.data.tokens?.access.token}`;

    thunkAPI.dispatch(scheduleTokenRefresh(response.data.tokens));
    return response.data;
  }
);

function scheduleTokenRefresh(tokens: Tokens) {
  const thunk = createAsyncThunk<void, Tokens>(
    'auth/scheduleTokenRefresh',
    async (authTokens, thunkAPI) => {
      clearInterval(tokenRefreshInterval);
      const access = authTokens
        ? authTokens.access
        : (thunkAPI.getState() as RootState).auth.tokens?.access;
      if (access) {
        const oneMinute = 1 * 60 * 1000;
        const interval = getExpirationTimeInMilli(access.expires) - oneMinute;

        if (interval >= 0) {
          tokenRefreshInterval = setInterval(() => {
            thunkAPI.dispatch(refreshTokens());
          }, interval);
        }
      }
    }
  );

  return thunk(tokens);
}

const login = createAsyncThunk<
  AuthResponse,
  { email: string; password: string }
>('auth/login', async ({ email, password }, thunkAPI) => {
  try {
    const response = await axios.post<AuthResponse>('/v1/auth/login', {
      email,
      password,
    });
    axios.defaults.headers.common.Authorization = `Bearer ${response.data.tokens?.access.token}`;

    thunkAPI.dispatch(scheduleTokenRefresh(response.data.tokens));

    return Promise.resolve(response.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});

const logout = createAsyncThunk('auth/logout', async () => {
  clearInterval(tokenRefreshInterval);
  await axios.get('/v1/auth/logout');
  axios.defaults.headers.common.Authorization = '';

  return Promise.resolve();
});

const logoutPatient = createAsyncThunk(
  'auth/logoutPatient',
  async (id: string) => {
    await axios.post(`v1/patients/${id}/logout`);
  }
);

/** Reducer */
const initialState: AuthState = {
  isPending: true,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData(state, action: PayloadAction<AuthResponse>) {
      axios.defaults.headers.common.Authorization = `Bearer ${action.payload.tokens.access.token}`;
      state.user = action.payload.user;
      state.tokens = action.payload.tokens;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.tokens = action.payload.tokens;
      })
      .addCase(login.rejected, (state) => {
        clearInterval(tokenRefreshInterval);
        delete state.user;
        delete state.tokens;
      })
      .addCase(refreshTokens.fulfilled, (state, action) => {
        state.isPending = false;
        state.user = action.payload.user;
        state.tokens = action.payload.tokens;
      })
      .addCase(refreshTokens.rejected, (state) => {
        clearInterval(tokenRefreshInterval);
        state.isPending = false;
        delete state.user;
        delete state.tokens;
      })
      .addCase(logout.pending, (state) => {
        delete state.user;
        delete state.tokens;
      });
  },
});

const { actions, reducer } = authSlice;
export const { setAuthData } = actions;
export {
  login,
  refreshTokens,
  logout,
  logoutPatient,
  authSelector,
  isAuthenticatedSelector,
};
export default reducer;
