import styled from '@emotion/styled';
import { space, SpaceProps } from 'styled-system';
import { Theme } from 'styles/system';

export type Props = SpaceProps;

const Separator = styled.div<Props, Theme>`
  width: 100%;
  border: ${({ theme }) => `0.5px solid ${theme.colors.gray[600]}`};
  ${space}
`;

Separator.defaultProps = {
  mb: 0,
  mt: 0,
};

export default Separator;
