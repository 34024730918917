import styled from '@emotion/styled';
import { Theme, typeScale } from 'styles/system';
import BaseButton, { Props } from './BaseButton';

const RoundedButton = styled(BaseButton)<Props, Theme>`
  background-color: ${({ color, theme }) => getThemeColor(theme, color)[100]};
  border-radius: 50px;
  color: ${({ theme }) => theme.primaryColor};
  font-size: ${typeScale.sm};
  padding: 4px 16px;

  &:hover {
    background-color: ${({ color, theme }) => getThemeColor(theme, color)[200]};
    color: ${({ color, theme }) => getThemeColor(theme, color)[800]};
  }
  &:active:focus {
    background-color: ${({ color, theme }) => getThemeColor(theme, color)[700]};
    color: ${({ theme }) => theme.textColorInverted};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.textColorOnDisabled};
    cursor: not-allowed;
  }
`;

function getThemeColor(theme: Theme, color?: Props['color']) {
  switch (color) {
    case 'primary':
      return theme.colors.primary;
    case 'secondary':
      return theme.colors.secondary;
    case 'tertiary':
      return theme.colors.accent;
    default:
      return theme.colors.primary;
  }
}

export default RoundedButton;
