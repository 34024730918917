/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector, isAuthenticatedSelector } from 'redux/authSlice';
import { UserRole } from 'types/patient';
import { RootState } from 'redux/rootReducer';

type Props = {
  children: React.ReactElement;
  admitedRoles: UserRole[];
} & RouteProps;

const PrivateRoute = ({ children, admitedRoles, ...rest }: Props) => {
  const auth = useSelector(authSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const userRole = auth.user?.role as UserRole;
  const allowAccess = isAuthenticated && admitedRoles.includes(userRole);
  const { theme } = useSelector((state: RootState) => state.app);

  return (
    <Route
      {...rest}
      render={(props: RouteProps) =>
        allowAccess ? (
          React.cloneElement(children, props)
        ) : (
          <Redirect
            to={{
              pathname: theme !== 'recuro' ? '/login' : 'login/recuro',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
