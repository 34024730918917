import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { HimAndHair, RegisteredPatient } from 'types/patient';
import {
  PatientChangesResponse,
  PatientStatusResponse,
  RequestStatus,
  ChangeRequestStatusResponse,
} from 'types/response';
import { RootState } from './rootReducer';

export type AdminState = {
  status?: PatientStatusResponse;
  changeRequestStatus?: ChangeRequestStatusResponse;
  patient?: RegisteredPatient;
  patientFetchStatus: RequestStatus;
  patientChanges?: RegisteredPatient;
  changesFetchStatus: RequestStatus;
  HHPatient?: HimAndHair;
};

const adminSelector = (state: RootState) => state.admin;
const patientStatusSelector = createSelector(
  adminSelector,
  (admin) => admin.status
);

const changeRequestStatusSelector = createSelector(
  adminSelector,
  (admin) => admin.changeRequestStatus
);

const getPatient = createAsyncThunk('admin/patient', async (id: string) => {
  const { data } = await axios.get<RegisteredPatient>(`/v1/patients/${id}`);
  return data;
});

const getHHPatient = createAsyncThunk('admin/hhpatient', async (id: string) => {
  const { data } = await axios.get<HimAndHair>(`/v1/himandhair-user/${id}`);
  return data;
});

const getPatientChanges = createAsyncThunk(
  'admin/patientChanges',
  async (id: string) => {
    const { data } = await axios.get<PatientChangesResponse>(
      `/v1/patients/${id}/changes`
    );
    return data;
  }
);

const getPatientStatus = createAsyncThunk('admin/status', async () => {
  const { data } = await axios.get<PatientStatusResponse>(
    '/v1/patients/status'
  );
  return data;
});

const getChangeRequestStatus = createAsyncThunk(
  'admin/statusChange',
  async () => {
    const { data } = await axios.get<ChangeRequestStatusResponse>(
      '/v1/patients/statusChange'
    );
    return data;
  }
);

/** Reducer */
const initialState: AdminState = {
  patientFetchStatus: RequestStatus.notStarted,
  changesFetchStatus: RequestStatus.notStarted,
};
const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearPatient(state) {
      state.patient = undefined;
      state.patientFetchStatus = RequestStatus.notStarted;
    },
    clearPatientChanges(state) {
      state.patientChanges = undefined;
    },
    setPersonalInformation(state, action) {
      if (state.patient) {
        state.patient.personal_information = action.payload || {};
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPatientStatus.fulfilled, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(getChangeRequestStatus.fulfilled, (state, action) => {
      state.changeRequestStatus = action.payload;
    });
    builder.addCase(getPatient.pending, (state) => {
      state.patientFetchStatus = RequestStatus.pending;
    });
    builder.addCase(getPatient.fulfilled, (state, action) => {
      state.patient = action.payload;
      state.patientFetchStatus = RequestStatus.success;
    });
    builder.addCase(getPatient.rejected, (state) => {
      state.patientFetchStatus = RequestStatus.failed;
    });
    builder.addCase(getHHPatient.pending, (state) => {
      state.patientFetchStatus = RequestStatus.pending;
    });
    builder.addCase(getHHPatient.fulfilled, (state, action) => {
      state.HHPatient = action.payload;
      state.patientFetchStatus = RequestStatus.success;
    });
    builder.addCase(getHHPatient.rejected, (state) => {
      state.patientFetchStatus = RequestStatus.failed;
    });
    builder.addCase(getPatientChanges.pending, (state) => {
      state.changesFetchStatus = RequestStatus.pending;
    });
    builder.addCase(getPatientChanges.fulfilled, (state, action) => {
      state.patientChanges = action.payload.changes;
      state.changesFetchStatus = RequestStatus.success;
    });
    builder.addCase(getPatientChanges.rejected, (state) => {
      state.changesFetchStatus = RequestStatus.failed;
    });
  },
});

const { actions, reducer } = adminSlice;
export const {
  clearPatient,
  clearPatientChanges,
  setPersonalInformation,
} = actions;
export {
  getPatient,
  getHHPatient,
  getPatientChanges,
  getPatientStatus,
  changeRequestStatusSelector,
  getChangeRequestStatus,
  patientStatusSelector,
};
export default reducer;
