import styled from '@emotion/styled';
import {
  space,
  typography,
  SpaceProps,
  TypographyProps,
  FlexboxProps,
  flexbox,
  DisplayProps,
  display,
} from 'styled-system';
import {
  textDecoration,
  TextDecorationProps,
  Theme,
  typeScale,
} from 'styles/system';

export type Props = SpaceProps &
  TypographyProps &
  TextDecorationProps &
  DisplayProps &
  FlexboxProps & {
    color?:
      | 'default'
      | 'primary'
      | 'primary-dark'
      | 'white'
      | 'light-gray'
      | 'gray'
      | 'dark-gray'
      | 'error'
      | 'black'
      | string;
    weight?: 'regular' | 'semi-bold' | 'bold';
    font?: 'primary' | 'secondary';
    transform?: 'uppercase' | 'lowercase';
    as?: React.ElementType | keyof JSX.IntrinsicElements;
    size?:
      | 'xs'
      | 'sm'
      | 'base'
      | 'lg'
      | 'xl'
      | '2xl'
      | '3xl'
      | '4xl'
      | '5xl'
      | '6xl';
  };

const Text = styled.span<Props, Theme>`
  font-family: ${({ theme, font }) =>
    font
      ? font === 'secondary'
        ? theme.secondaryFont
        : theme.primaryFont
      : 'inherit'};
  font-size: ${({ size }) => (size ? typeScale[size] : 'inherit')};
  font-weight: ${({ weight }) =>
    weight === 'semi-bold' ? '600' : weight === 'bold' ? '700' : '400'};
  text-transform: ${({ transform }) => transform || 'none'};
  color: ${({ theme, color }) =>
    color === 'primary'
      ? theme.colors.primary[400]
      : color === 'primary-dark'
      ? theme.colors.primary[500]
      : color === 'white'
      ? theme.colors.white
      : color === 'light-gray'
      ? theme.colors.gray[400]
      : color === 'gray'
      ? theme.colors.gray[500]
      : color === 'dark-gray'
      ? theme.colors.gray[600]
      : color === 'black'
      ? theme.colors.gray[900]
      : color === 'error'
      ? theme.colors.error[500]
      : color === 'warning'
      ? theme.colors.warning[500]
      : color || theme.colors.gray[700]};
  ${space}
  ${typography}
  ${display}
  ${flexbox}
  ${textDecoration}
`;

Text.defaultProps = {
  color: 'default',
  weight: 'regular',
};

export default Text;
