import React, { HTMLAttributes } from 'react';

type Props = HTMLAttributes<SVGElement> & {
  width?: number;
  height?: number;
  strokeWidth?: number;
};

function ClipboardCopy({ width, height, strokeWidth, ...rest }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width={width}
      height={height}
      {...rest}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
      />
    </svg>
  );
}

ClipboardCopy.defaultProps = {
  width: 18,
  height: 16,
  strokeWidth: 2,
};

export default ClipboardCopy;
