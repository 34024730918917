export const primaryFont = '"Inter", sans-serif';
export const secondaryFont = '"DM Serif Display", serif';

export const typeScale = {
  xs: '0.75rem',
  sm: '0.875rem',
  base: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '2rem',
  '3xl': '2.375rem',
  '4xl': '2.625rem',
  '5xl': '3rem',
  '6xl': '4rem',
};
