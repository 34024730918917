import React from 'react';
// import ReactDOM from 'react-dom';
import { hydrate, render } from 'react-dom';

import { NotificationContainer } from 'react-notifications';
import App from 'components/App';
import RootProvider from 'components/providers/RootProvider';
import './utils/axiosConfig';

import 'react-notifications/lib/notifications.css';

const rootElement = document.getElementById('root');

const Root = () => (
  <React.StrictMode>
    <RootProvider>
      <NotificationContainer />
      <App />
    </RootProvider>
  </React.StrictMode>
);

if (rootElement?.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}
