import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Theme, defaultTheme } from 'styles/system';
import ClipLoader from 'react-spinners/ClipLoader';
import BaseButton from './BaseButton';
import { Props } from '../Button';

const ContainedButton = ({ loading, disabled, children, ...rest }: Props) => {
  const loaderStyles = css`
    border-color: ${defaultTheme.textColorOnDisabled};
    border-bottom-color: transparent;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    transition: 1s;
  `;
  const isDisabled = loading || disabled;
  return (
    <Wrapper {...rest} disabled={isDisabled}>
      <ClipLoader loading={loading} css={loaderStyles} />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(BaseButton)<Props, Theme>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme, color }) =>
    color === 'tertiary'
      ? theme.colors.white
      : color === 'secondary'
      ? theme.colors.primary[200]
      : theme.primaryColor};
  color: ${({ theme, color }) =>
    color === 'tertiary'
      ? theme.colors.primary[500]
      : color === 'secondary'
      ? theme.colors.primary[500]
      : theme.textColorOnPrimary};
  min-width: 100px;

  &:hover {
    background-color: ${({ theme, color }) =>
      color === 'tertiary'
        ? theme.colors.gray[200]
        : color === 'secondary'
        ? theme.colors.primary[300]
        : theme.colors.primary[700]};
    color: ${({ color, theme }) =>
      color === 'tertiary'
        ? theme.colors.primary[700]
        : color === 'secondary'
        ? theme.colors.primary[600]
        : theme.textColorOnPrimary};
  }
  &:active:focus {
    background-color: ${({ theme, color }) =>
      color === 'tertiary'
        ? theme.colors.gray[300]
        : color === 'secondary'
        ? theme.colors.primary[400]
        : theme.colors.primary[800]};
    color: ${({ color, theme }) =>
      color === 'tertiary'
        ? theme.colors.primary[700]
        : color === 'secondary'
        ? theme.colors.primary[700]
        : theme.textColorOnPrimary};
    outline: 1px dashed;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.textColorOnDisabled};
  }
`;

export default ContainedButton;
