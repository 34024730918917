import React from 'react';
import { isNil } from 'lodash';
import { defaultTheme, recuroTheme } from 'styles/system';
import { UserType } from 'types/patient';
import { getInitials } from 'utils/string';
import { Box, Text } from '..';

export type Props = {
  title: string;
  description: string;
  count?: number;
  color?: UserType | 'change' | string;
  todayCount?: number;
};

export default function CountBox({
  title,
  description,
  count,
  color = 'regular',
  todayCount,
}: Props) {
  const initials = getInitials(title);
  const backgroundColor =
    color === 'regular'
      ? defaultTheme.primaryColor
      : color === 'contrave'
      ? defaultTheme.colors.secondary[500]
      : color === 'docTalkGo'
      ? defaultTheme.colors.accent[600]
      : color === 'change'
      ? defaultTheme.colors.primary[400]
      : color === 'recuro'
      ? recuroTheme.colors.primary[500]
      : color;
  return (
    <Box display="flex" minWidth={255} maxWidth={255} bg="white">
      <Box
        borderRadius="8px 0 0 8px"
        backgroundColor={backgroundColor}
        minWidth="64px"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
      >
        <Text weight="semi-bold" color="white">
          {initials}
        </Text>
      </Box>
      <Box
        borderRadius="0 8px 8px 0"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={defaultTheme.colors.gray[300]}
        padding="12px 8px 12px 8px"
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <Text weight="semi-bold" size="sm">
          {title}
        </Text>
        <Text color="gray" size="sm" mt="5px">
          {!isNil(count) && pluralize(description, count)}
        </Text>
        <Text color="red" size="sm" mt="5px" fontWeight="bold">
          {todayCount !== undefined ? `${todayCount} enrolled today.` : ''}
        </Text>
      </Box>
    </Box>
  );
}

function pluralize(text: string, count: number) {
  return `${count} ${text}${count !== 1 ? 's' : ''}`;
}
