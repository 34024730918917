import React, { MouseEvent, ButtonHTMLAttributes } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutProps, SpaceProps } from 'styled-system';
import {
  ContainedButton,
  LinkButton,
  OutlinedButton,
  RoundedButton,
} from './components';

type Size = 'sm' | 'md' | 'lg';
type Type = 'button' | 'submit' | 'reset';
type Variant = 'contained' | 'link' | 'outlined' | 'rounded';
type Color = 'primary' | 'secondary' | 'tertiary';
type Shadow = 'base' | 'md' | 'lg' | 'xl' | 'xxl' | 'inset' | 'none';

export type Props = ButtonHTMLAttributes<HTMLButtonElement> &
  LayoutProps &
  SpaceProps & {
    children?: React.ReactNode;
    className?: string;
    color?: Color;
    disabled?: boolean;
    paddingSize: Size;
    shadow: Shadow;
    type?: Type;
    variant?: Variant;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    loading?: boolean;
    route?: string;
  };

function Button({ loading, variant, route, ...rest }: Props) {
  const history = useHistory();
  const commonProps =
    route !== undefined
      ? {
          ...rest,
          role: 'link',
          onClick: () => history.push(route),
        }
      : {
          ...rest,
          role: 'button',
        };
  switch (variant) {
    case 'link':
      return <LinkButton {...rest} {...commonProps} loading={loading} />;
    case 'outlined':
      return <OutlinedButton {...rest} {...commonProps} />;
    case 'rounded':
      return <RoundedButton {...rest} {...commonProps} />;
    default:
      return <ContainedButton loading={loading} {...commonProps} />;
  }
}

Button.defaultProps = {
  color: 'primary',
  disabled: false,
  paddingSize: 'sm',
  shadow: 'none',
  type: 'button',
  variant: 'contained',
  loading: false,
  route: undefined,
} as Props;

export default Button;
