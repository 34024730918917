import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & { active?: boolean };

const LinkButton = styled.button<Props, Theme>`
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: ${({ active, theme }) =>
    active ? theme.colors.gray[900] : theme.colors.gray[600]};
  display: inline-flex;
  padding: 4px 12px;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color 0.1s linear;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[100]};
    color: ${({ theme }) => theme.colors.gray[800]};
  }
  &:active:focus {
    background-color: ${({ theme }) => theme.colors.gray[200]};
    color: ${({ theme }) => theme.colors.gray[800]};
  }
`;

export default LinkButton;
