import React from 'react';
import styled from '@emotion/styled';
import ChevronDown from 'assets/icons/ChevronDown';
import ChevronUp from 'assets/icons/ChevronUp';
import { Box } from 'components/common';
import { Theme } from 'styles/system';

type TriggerProps = {};

const Container = styled.div<TriggerProps, Theme>`
  position: absolute;
  top: 0;
  right: 0;
  .arrows {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px;
    color: ${({ theme }) => theme.colors.gray[500]};
    .down {
      margin-top: -8px;
    }
  }
`;

function Trigger() {
  return (
    <Container role="button" tabIndex={0}>
      <Box minWidth={18}>
        <div className="arrows">
          <div className="up">
            <ChevronUp width={20} height={16} strokeWidth={3} />
          </div>
          <div className="down">
            <ChevronDown width={20} height={16} strokeWidth={3} />
          </div>
        </div>
      </Box>
    </Container>
  );
}

Trigger.defaultProps = {};

export default Trigger;
