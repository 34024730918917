import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import CheckSvg from 'assets/icons/CheckSvg';
import styled from '@emotion/styled';
import { space, SpaceProps } from 'styled-system';
import { Theme } from 'styles/system';
import { Text } from 'components/common';
import { Label, Input } from './components';

export type Props = InputHTMLAttributes<HTMLInputElement> &
  SpaceProps & {
    id: string;
    label: string | React.ReactNode;
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };

const Wrapper = styled.div<SpaceProps, Theme>`
  ${space}
`;

function Checkbox({
  id,
  checked = false,
  disabled = false,
  label,
  onChange,
  ...rest
}: Props) {
  return (
    <Wrapper {...rest}>
      <Label htmlFor={id} checked={checked} disabled={disabled}>
        <div className="checkbox">
          <CheckSvg />
        </div>
        <Text className="checkbox-label">{label}</Text>
        <Input
          aria-checked={checked}
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          type="checkbox"
          {...rest}
        />
      </Label>
    </Wrapper>
  );
}

export default Checkbox;
