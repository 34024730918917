import { HTMLAttributes } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = HTMLAttributes<HTMLDivElement> & { hasPrepend?: boolean };

const InputContainer = styled.div<Props, Theme>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 4px;

  > input,
  textarea {
    flex: 1 1 auto;
    ${({ hasPrepend }) =>
      hasPrepend
        ? css`
            border-left: none;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            padding-left: 8px;
            &:focus {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            }
          `
        : css`
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
            padding-left: 20px;
            &:focus {
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;
            }
          `}
  }

  > span {
    margin: 0;
  }
`;

export default InputContainer;
