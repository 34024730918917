import styled from '@emotion/styled';
import { defaultTheme } from 'styles/system';

import 'antd/lib/tabs/style/css';

export type Props = {
  variant?: 'primary' | 'secondary';
};

const StyledAntdTab = styled.div<Props>`
  .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-around;

    & > .ant-tabs-tab {
      width: 100%;
      display: flex;
      justify-content: center;

      &:hover {
        color: ${color};
      }

      &.ant-tabs-tab-active > .ant-tabs-tab-btn {
        color: ${color};
      }
    }

    & > .ant-tabs-ink-bar {
      background: ${color};
      height: 1px;
    }
  }
`;

StyledAntdTab.defaultProps = {
  variant: 'primary',
};

function color({ variant }: Props) {
  return variant === 'primary'
    ? defaultTheme.primaryColor
    : defaultTheme.colors.secondary[500];
}

export default StyledAntdTab;
