import React from 'react';
import CopyForm, { Field } from 'components/common/CopyForm/CopyForm';
import { ContactInformation } from 'types/patient';

type Props = {
  data: ContactInformation;
  email?: string;
};

ShippingInformation.defaultProps = {
  email: undefined,
};

function ShippingInformation({ data, email }: Props) {
  const fields: Field[] = [
    { id: 1, label: 'Shipping address line 1', value: data.address_one },
    { id: 2, label: 'Shipping address line 2', value: data.address_two },
    { id: 3, label: 'City', value: data.city },
    { id: 4, label: 'State', value: data.state },
    { id: 5, label: 'Zip code', value: data.zipcode },
    {
      id: 6,
      label: 'Phone number',
      value: data?.phone_number
        ?.replace(/^\+?1|[()-]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
    },
    { id: 7, label: 'Email', value: email },
  ];
  return (
    <div>
      <CopyForm fields={fields} />
    </div>
  );
}

export default ShippingInformation;
