import styled from '@emotion/styled';
import { Theme } from 'styles/system';
import BaseButton, { Props } from './BaseButton';

const OutlinedButton = styled(BaseButton)<Props, Theme>`
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  box-shadow: ${({ theme }) => theme.shadows.md};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.primaryColor};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[200]};
  }
  &:active:focus {
    background-color: ${({ theme }) => theme.colors.gray[300]};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray[200]};
    color: ${({ theme }) => theme.textColorOnDisabled};
  }
`;

export default OutlinedButton;
