import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  color,
  ColorProps,
  shadow,
  ShadowProps,
} from 'styled-system';
import { Theme } from 'styles/system';

type Props = HTMLAttributes<HTMLDivElement> &
  BackgroundProps &
  BorderProps &
  FlexboxProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  ColorProps &
  ShadowProps &
  TypographyProps & {
    as?: React.ElementType | keyof JSX.IntrinsicElements;
    bg?: 'default' | 'white' | 'light-gray';
  };

const Box = styled.div<Props, Theme>`
  background-color: ${({ bg, backgroundColor, theme }) =>
    bg === 'light-gray'
      ? theme.colors.gray[100]
      : bg === 'white'
      ? theme.colors.white
      : backgroundColor
      ? backgroundColor.toString()
      : 'initial'};
  ${background}
  ${border}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${color}
  ${typography}
  ${shadow}
`;

Box.defaultProps = {
  bg: 'default',
};

export default Box;
