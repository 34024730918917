import axios from 'axios';
import { debounce, isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';

const notificationTimeOut = 13000;

axios.defaults.baseURL = getApiUrl();
axios.defaults.withCredentials = true;

const debouncedNotificationError = debounce((message, title) => {
  NotificationManager.error(message, title, notificationTimeOut);
}, 250);

// Intercept errors
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let message = '';
    let title = '';

    if (error.message === 'Network Error') {
      title = 'Please try again';
      message = 'Network connection error';

      debouncedNotificationError(message, title);
      return Promise.reject(error);
    }

    const errorWasHandled =
      error.response?.status === 401 ||
      error.response?.status === 409 ||
      error.response?.status === 404;

    if (!errorWasHandled) {
      title = 'Something went wrong';
      const responseMessage = error?.response?.data?.message;
      message = isEmpty(responseMessage)
        ? 'If the problem persist please call customer support (406) 642 6040'
        : responseMessage;
    }

    if (!isEmpty(message) && !isEmpty(title)) {
      debouncedNotificationError(message, title);
    }
    return Promise.reject(error);
  }
);

/**
 * In production, the env var REACT_APP_API_URL has a value of https://api.{{domain}} or https://staging-api.{{domain}}
 * This function replaces {{domain}} with the domain name hosting this React app.
 * Domains must be the same between front end and back end in order for secure httpOnly cookies to work
 * @returns {string} apiURL
 */
function getApiUrl() {
  const rootHostname = window.location.hostname.replace(
    /(www|master|staging)\./g,
    ''
  );

  return process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL?.replace('{{domain}}', rootHostname)
    : process.env.REACT_APP_API_URL;
}
