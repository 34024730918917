import React from 'react';

type Props = {
  width?: number;
  height?: number;
  strokeWidth?: number;
};

function CheckCircleSvg({ strokeWidth, ...rest }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...rest}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

CheckCircleSvg.defaultProps = {
  width: 18,
  height: 16,
  strokeWidth: 2,
};

export default CheckCircleSvg;
