import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = {
  first?: boolean;
  last?: boolean;
  mobile?: boolean;
};

const grouper = ({ first, last, mobile }: Props) => {
  if (mobile) return '0';
  if (first) return '0 12px 0 0';
  if (last) return '0 0 0 12px';
  return '0 12px';
};

const LinkContainer = styled.div<Props, Theme>`
  display: flex;
  flex-direction: ${({ mobile }) => (mobile ? 'row-reverse' : 'column')};
  justify-content: ${({ mobile }) => (mobile ? 'flex-end' : 'center')};
  align-items: center;
  padding-top: 12px;
  margin: ${grouper};
  cursor: pointer;
`;

LinkContainer.defaultProps = {
  first: false,
  last: false,
  mobile: false,
};

export default LinkContainer;
