import React from 'react';
import styled from '@emotion/styled';
import { FakeField } from 'components/common';
import ClipboardCopy from 'assets/icons/ClipboardCopy';
import CopyButton from './components/CopyButton';

type Props = {
  label?: string;
  value?: string;
  type?: 'input' | 'textarea';
  disabled?: boolean;
  ccExpDate?: boolean;
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

function CopyField({ label, value = '', type, disabled, ccExpDate }: Props) {
  const copyText = () => {
    if (!disabled) {
      if (ccExpDate) {
        navigator.clipboard.writeText(value.replace('/', ''));
      } else navigator.clipboard.writeText(value.toUpperCase());
    }
  };
  return (
    <Container>
      <FakeField
        label={label}
        value={value}
        onClick={copyText}
        type={type}
        disabled={disabled}
      />
      <CopyButton
        type="button"
        title="Copy to clipboard"
        onClick={copyText}
        disabled={disabled}
      >
        <ClipboardCopy width={28} height={28} />
      </CopyButton>
    </Container>
  );
}

CopyField.defaultProps = {
  label: '',
  value: '',
  type: 'input',
  disabled: false,
  ccExpDate: false,
};

export default CopyField;
