import React from 'react';
import { isEmpty } from 'lodash';
import { CopyField } from 'components/common';
import { EmptyForm } from 'components/common/copyforms';

export type Field = {
  id: number;
  label?: string;
  value?: string;
  type?: 'input' | 'textarea';
};

type Props = {
  fields: Field[];
};

function CopyForm({ fields = [] }: Props) {
  const validFields = fields.filter((field) => field.value !== undefined);
  return (
    <div>
      {!isEmpty(validFields) ? (
        <div className="form">
          <div className="fields">
            {validFields.map(({ id, label, value, type }) => (
              <CopyField
                key={id}
                label={label}
                value={value}
                type={type}
                disabled={value === 'Value deleted'}
              />
            ))}
          </div>
        </div>
      ) : (
        <EmptyForm />
      )}
    </div>
  );
}

export default CopyForm;
