import React from 'react';
import ReactSelect, { ValueType, Styles } from 'react-select';
import styled from '@emotion/styled';
import { defaultTheme as theme, recuroTheme, Theme } from 'styles/system';
import { Helper, Label } from 'components/common/Field/components';

type OptionType = {
  label: string;
  value: string | number;
};

type Props = {
  id: string;
  disabled?: boolean;
  label?: string;
  helper?: string;
  status?: 'valid' | 'invalid';
  options: OptionType[];
  onChange: React.Dispatch<React.SetStateAction<ValueType<OptionType>>>;
  selectedOption: ValueType<OptionType>;
  isRecuro?: boolean;
};

type StyledProps = {
  status?: 'valid' | 'invalid';
};

const Wrapper = styled.div<StyledProps, Theme>`
  .react-select-container {
    .react-select__control {
      border-width: 1px;
      border-style: solid;
      border-color: ${({ status }) =>
        status === 'invalid'
          ? theme.colors.error[500]
          : status === 'valid'
          ? theme.colors.success[600]
          : theme.colors.gray[400]};
    }
  }
`;

const customStyles: Styles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused
      ? `1px solid ${theme.colors.primary[500]}`
      : `1px solid ${theme.colors.gray[400]}`,
    borderRadius: '8px',
    boxShadow: 'none',
    height: '50px',
    ':hover': {
      border: `1px solid ${theme.colors.primary[500]}`,
      boxShadow: 'none',
    },
    outline: state.isFocused ? '1px dashed' : '0px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      state.isSelected && !state.isDisabled
        ? theme.colors.primary[500]
        : state.isFocused
        ? theme.colors.primary[100]
        : 'initial',
    color:
      state.isSelected && !state.isDisabled
        ? theme.colors.white
        : state.isDisabled
        ? theme.colors.gray[400]
        : theme.colors.gray[700],
    ':hover': {
      backgroundColor:
        state.isSelected && !state.isDisabled
          ? theme.colors.primary[500]
          : state.isDisabled
          ? 'initial'
          : theme.colors.primary[100],
      boxShadow: 'none',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? theme.colors.gray[400] : theme.colors.gray[700],
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 1100,
  }),
};

const recuroStyle: Styles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused
      ? `1px solid ${recuroTheme.colors.primary[500]}`
      : `1px solid ${recuroTheme.colors.gray[400]}`,
    borderRadius: '8px',
    boxShadow: 'none',
    height: '50px',
    ':hover': {
      border: `1px solid ${recuroTheme.colors.primary[500]}`,
      boxShadow: 'none',
    },
    outline: state.isFocused ? '1px dashed' : '0px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      state.isSelected && !state.isDisabled
        ? recuroTheme.colors.primary[500]
        : state.isFocused
        ? recuroTheme.colors.primary[100]
        : 'initial',
    color:
      state.isSelected && !state.isDisabled
        ? recuroTheme.colors.white
        : state.isDisabled
        ? recuroTheme.colors.gray[400]
        : recuroTheme.colors.gray[700],
    ':hover': {
      backgroundColor:
        state.isSelected && !state.isDisabled
          ? recuroTheme.colors.primary[500]
          : state.isDisabled
          ? 'initial'
          : recuroTheme.colors.primary[100],
      boxShadow: 'none',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled
      ? recuroTheme.colors.gray[400]
      : recuroTheme.colors.gray[700],
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 1100,
  }),
};

function Select({
  id,
  disabled = false,
  label,
  helper,
  status,
  options,
  selectedOption,
  onChange,
  isRecuro,
}: Props) {
  const shouldShowHelper = helper !== '';
  return (
    <Wrapper status={status}>
      {label && (
        <div style={{ margin: '5px 0' }}>
          <Label disabled={disabled} htmlFor={id}>
            {label}
          </Label>
        </div>
      )}
      <ReactSelect
        className="react-select-container"
        classNamePrefix="react-select"
        value={selectedOption}
        isSearchable={false}
        onChange={onChange}
        options={options}
        styles={isRecuro ? recuroStyle : customStyles}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        tabIndex="0"
      />
      {shouldShowHelper && (
        <Helper id="select-Help" disabled={disabled} status={status}>
          {helper}
        </Helper>
      )}
    </Wrapper>
  );
}

Select.defaultProps = {
  label: '',
  disabled: false,
  helper: '',
  status: undefined,
  isRecuro: false,
};

export default Select;
