import styled from '@emotion/styled';
import { Theme } from 'styles/system';

export type Props = {
  checked?: boolean;
  disabled?: boolean;
};

const Label = styled.label<Props, Theme>`
  display: flex;
  align-items: center;
  margin: 10px 0;
  .checkbox {
    background: ${({ checked, disabled, theme }) =>
      checked
        ? theme.primaryColor
        : disabled
        ? theme.disabled
        : theme.colors.white};
    border: 1px solid
      ${({ checked, theme }) =>
        checked ? theme.colors.white : theme.colors.gray[400]};
    color: ${({ disabled, theme }) =>
      disabled ? theme.disabled : theme.colors.white};
    box-sizing: border-box;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  .checkbox-label {
    width: calc(100% - 24px);
    margin-left: 16px;
    color: ${({ disabled, theme }) =>
      disabled ? theme.textColorDisabled : 'initial'};
  }
  &:focus-within {
    outline: 1px dashed;
  }
`;

export default Label;
