import React from 'react';
import Box from '../Box/Box';

type Props = {
  children: React.ReactNode;
};

function Container({ children }: Props) {
  return (
    <Box
      maxWidth={{ xl: '1440px' }}
      width={{
        xl: '90%',
        lg: '95%',
        md: '80%',
        sm: '85%',
        xs: '90%',
        _: '95%',
      }}
      m="0 auto"
    >
      {children}
    </Box>
  );
}

export default Container;
