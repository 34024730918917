import React from 'react';
import { Box, Text, Paragraph, List } from 'components/common';

type Props = {
  children?: React.ReactNode[];
};

DeliveryDisclaimer.defaultProps = {
  children: null,
};

function DeliveryDisclaimer({ children }: Props) {
  return (
    <Box>
      <Paragraph>
        <Text weight="semi-bold" color="primary-dark">
          Orders generally ship within 2 business days of receipt at Ridgeway.
          Refill orders take up to 2 business days as well.
        </Text>
      </Paragraph>
      <List variant="primary">
        <List.Item>
          Please be sure to update your Ridgeway Pharmacy online account
          information
        </List.Item>
        {children}
      </List>
    </Box>
  );
}

export default DeliveryDisclaimer;
