import React, { useState } from 'react';
import MenuIcon from 'assets/icons/Menu';
import MobileHeaderIcon from 'assets/icons/mobile-header-icon.svg';
import styled from '@emotion/styled';
import { defaultTheme, Theme } from 'styles/system/themes';
import Link from 'components/common/Nav/components/Link';
import 'react-responsive-modal/styles.css';
import { RouterLink, Modal } from 'components/common';
import routes from 'constants/routes';
import { isAuthenticatedSelector } from 'redux/authSlice';
import { useSelector } from 'react-redux';

type Props = {
  children: React.ReactNode;
};

type StyledProps = {};

const Container = styled.div<StyledProps, Theme>`
  position: relative;
  .burger-menu {
    width: 32px;
    height: 32px;
    background-color: rgba(14, 122, 139, 0.2);
    color: ${({ theme }) => theme.colors.primary[500]};
    border-radius: 8px;
    padding: 6px;
  }

  button {
    background: transparent;
    border: none;
    outline: 0;
  }
`;

const ModalStyles = {
  modal: {
    width: '100%',
    minHeight: '326px',
  },
};

const Menu = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    height: 66px;
    .logo {
      margin-top: -40px;
      margin-left: -16px;
    }
  }
  .nav-links {
    min-height: 210px;
    margin: 0 24px;
  }
  .login-button {
    background: ${defaultTheme.colors.gray[100]};
    height: 50px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    .login-text {
      color: ${defaultTheme.colors.primary[500]};
      font-size: 16px;
      cursor: pointer;
    }
  }

  a.login-router:active,
  a.login-router:focus {
    outline: 0;
  }
`;

function MobileMenu({ children }: Props) {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <button type="button" onClick={() => setOpen(!open)}>
        <div className="burger-menu">
          <MenuIcon width={20} height={20} />
        </div>
      </button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        styles={ModalStyles}
        blockScroll={false}
      >
        <Menu>
          <div className="header">
            <img
              className="logo"
              src={MobileHeaderIcon}
              alt="mobile-nav-logo"
              width={50}
              height={120}
            />
          </div>
          <div className="nav-links">
            {React.Children.map(children, (child) =>
              React.cloneElement(child as JSX.Element, {
                mobile: true,
                closeModal: () => setOpen(false),
              })
            )}
          </div>
          <RouterLink
            to={routes.login.path}
            onClick={() => setOpen(false)}
            className="login-router"
          >
            <div className="login-button">
              <p className="login-text">
                {isAuthenticated ? 'Go to Member Dashboard' : 'Login'}
              </p>
            </div>
          </RouterLink>
        </Menu>
      </Modal>
    </Container>
  );
}

MobileMenu.Link = Link;

export default MobileMenu;
