import React from 'react';
import styled from '@emotion/styled';
import { Text } from 'components/common';
import { Theme, typeScale } from 'styles/system';

type Props = {
  label?: string;
  value?: string;
  onClick?: () => void;
  type?: 'input' | 'textarea';
  disabled?: boolean;
};

const Container = styled.div`
  flex-grow: 1;
`;

const Input = styled.div<Props, Theme>`
  box-sizing: border-box;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray[100] : theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.gray[400]}`};
  border-radius: 8px;
  height: ${({ type }) => (type === 'textarea' ? '140px' : '50px')};
  min-width: 280px;
  @media (max-width: 360px) {
    min-width: 90%;
  }
  padding-left: 20px;
  padding-right: 20px;
  transition: border-color 0.5s ease-out;
  display: flex;
  align-items: ${({ type }) => (type === 'textarea' ? 'flex-start' : 'center')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Label = styled.div<Props, Theme>`
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: ${typeScale.sm};
  font-weight: 400;
  line-height: 22px;
  margin: 5px 0 0 0;
`;

function FakeField({ label, value, onClick, type, disabled }: Props) {
  return (
    <Container>
      <Label>
        <Text size="sm">{label}</Text>
      </Label>
      <Input onClick={onClick} type={type} disabled={disabled}>
        <Text size="base" color={disabled ? 'gray' : 'default'}>
          {value}
        </Text>
      </Input>
    </Container>
  );
}

FakeField.defaultProps = {
  label: '',
  value: '',
  onClick: () => {},
  type: 'input',
  disabled: false,
};

export default FakeField;
