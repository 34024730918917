/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { defaultTheme } from 'styles/system';
import CloseCircle from 'assets/icons/CloseCircle';
import ExclamationCircleSvg from 'assets/icons/ExclamationCircleSvg';
import { Box, Text } from '..';

export interface Props {
  type: 'success' | 'warning' | 'error';
  title: string;
  message?: string;
}

export default function Alert({ title, message, type }: Props) {
  const { color, icon } = getCustomizationForType(type);
  return (
    <Box
      borderRadius={4}
      backgroundColor={color[50]}
      padding="20px"
      display="flex"
      alignItems={message ? 'unset' : 'center'}
    >
      <Box color="white" width={20} height={20}>
        {React.createElement(icon, { fill: color[600], width: 20, height: 20 })}
      </Box>
      <Box ml="10px" fontSize=".875rem">
        <Box>
          <Text color={color[700]} weight="semi-bold">
            {title}
          </Text>
        </Box>
        {message && (
          <Box mt="8px">
            <Text color={color[600]}>{message}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}

Alert.defaultProps = {
  message: undefined,
};

function getCustomizationForType(type: 'success' | 'warning' | 'error') {
  switch (type) {
    case 'error':
      return {
        color: defaultTheme.colors.error,
        icon: CloseCircle,
      };
    case 'warning':
    default:
      return {
        color: defaultTheme.colors.warning,
        icon: ExclamationCircleSvg,
      };
  }
}
