import React from 'react';
import ReduxProvider from './ReduxProvider';
import ThemeProvider from './ThemeProvider';

type Props = {
  children: React.ReactNode;
};

function RootProvider({ children }: Props) {
  return (
    <ReduxProvider>
      <ThemeProvider>{children}</ThemeProvider>
    </ReduxProvider>
  );
}

export default RootProvider;
