import React from 'react';
import LogoSvg from 'assets/logos/LogoSvg';
import RecuroLogo from 'assets/logos/RecuroLogosHorizontalFullColorV2.svg';
import { RouterLink } from 'components/common';
import routes from 'constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import Container from './Container';
import MobileMenu from './MobileMenu';

export type Props = {
  children: React.ReactNode;
  scrolled: boolean;
};

function MobileNavbar({ children, scrolled }: Props) {
  const { theme } = useSelector((state: RootState) => state.app);
  return (
    <Container mobile scrolled={scrolled}>
      {theme !== 'recuro' ? (
        <RouterLink to={routes.home.path}>
          <LogoSvg width={133} height={56} />
        </RouterLink>
      ) : (
        <img src={RecuroLogo} alt="Recuro logo" height="56" width="150" />
      )}
      <MobileMenu>{children}</MobileMenu>
    </Container>
  );
}

export default MobileNavbar;
