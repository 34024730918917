import React, { ReactNode } from 'react';
import DefaultTrigger from './DefaultTrigger';
import RoundedTrigger from './RoundedTrigger';
import ArrowTrigger from './ArrowTrigger';

type Props = {
  open?: boolean;
  title: ReactNode;
  subtitle?: string;
  subtitleColor?: 'gray' | 'warning' | string;
  variant?: 'default' | 'rounded';
  position?: 'first' | 'last' | 'middle';
  background?: 'default' | 'white' | 'gray';
  collapsibleArea?: 'full-header' | 'arrows-only';
};

Trigger.defaultProps = {
  open: false,
  subtitle: '',
  subtitleColor: 'gray',
  variant: 'default',
  position: 'middle',
  background: 'default',
  collapsibleArea: 'full-header',
};

function Trigger({
  background,
  open,
  position,
  title,
  subtitle,
  subtitleColor,
  variant,
  collapsibleArea,
}: Props) {
  return (
    <>
      {variant === 'rounded' ? (
        collapsibleArea === 'arrows-only' ? (
          <ArrowTrigger />
        ) : (
          <RoundedTrigger
            background={background}
            position={position}
            title={title}
            subtitle={subtitle}
            subtitleColor={subtitleColor}
          />
        )
      ) : (
        <DefaultTrigger title={title} open={open} />
      )}
    </>
  );
}

export default Trigger;
