const routes = {
  home: { path: '/', label: 'Home' },
  prescriptions: { path: '/prescriptions', label: 'Prescriptions' },
  policies: { path: '/policies', label: 'Policies' },
  about: { path: '/about', label: 'About' },
  faq: { path: '/faq', label: 'FAQ' },
  contactUs: { path: '/contact-us', label: 'Contact Us' },
  partnerWithUs: { path: '/partner', label: 'Partner With Us' },
  forgotPassword: { path: '/forgot-password', label: 'Forgot Password' },
  resetPassword: { path: '/reset-password', label: 'Reset Password' },
  login: { path: '/login', label: 'Login' },
  signup: { path: '/signup', label: 'Sign up' },
  // Register wizard routes
  wizard: { path: '/register', label: 'Wizard' },
  typeOfPatient: {
    path: '/register/type-of-patient',
    label: 'Type of Patient',
  },
  personalInformation: {
    path: '/register/personal-information',
    label: 'Personal Information',
  },
  shippingInformation: {
    path: '/register/shipping-information',
    label: 'Shipping Information',
  },
  healthInformation: {
    path: '/register/health-information',
    label: 'Health Information',
  },
  dependentInformation: {
    path: '/register/dependent-information',
    label: 'Dependent Information',
  },
  insuranceInformation: {
    path: '/register/insurance-information',
    label: 'Insurance Information',
  },
  prescriptionInformation: {
    path: '/register/prescription-information',
    label: 'Prescription Information',
  },
  paymentInformation: {
    path: '/register/payment-information',
    label: 'Payment Information',
  },
  registrationCompleted: {
    path: '/register/completed',
    label: 'Registration Completed',
  },
  newHimAndHairUser: {
    path: '/him-and-hair',
    label: 'Him and hair',
  },
  himAndHairCompleted: {
    path: '/completed',
    label: 'Registration Completed',
  },

  // Member dashboard routes
  memberHome: { path: '/member', label: 'Home' },
  newPrescription: {
    path: '/member/new-prescription',
    label: 'New Prescription',
  },
  // refillPrescription: {
  //   path: '/member/refill-prescription',
  //   label: 'Refill Prescription',
  // },

  // Admin dashboard routes
  adminHome: { path: '/admin', label: 'New patients' },
  changes: { path: '/admin/changes', label: 'Change requests' },
  adminPrescriptions: { path: '/admin/prescriptions', label: 'Prescriptions' },
  adminPatientDetail: {
    path: '/admin/patient-detail',
    label: 'Patient Detail',
  },
  adminContraveLegacy: {
    path: '/admin/contrave-legacy',
    label: 'Contrave Legacy',
  },
  adminProcessedPatients: {
    path: '/admin/processed-patients',
    label: 'Processed patients',
  },
  adminHimAndHairPatients: {
    path: '/admin/him-and-hair',
    label: 'Him and hair patients',
  },
};

export default Object.freeze(routes);
