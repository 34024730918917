import React from 'react';
import { chunk } from 'lodash';
import { Event } from 'types/forms';
import { Box, Radio, Text } from '../..';
import { Input } from '../SatisfactionSurvey';

type Props = {
  question: string;
  answers: string[];
  input: Input;
  handleInputChange: (e: Event) => void;
};

export default function SurveyQuestion({
  question,
  answers,
  input,
  handleInputChange,
}: Props) {
  const columns = chunk(answers, Math.ceil(answers.length / 2));
  return (
    <Box pb="20px">
      <Text>{question}</Text>
      <Box display="flex" flexWrap="wrap">
        {columns.map((column, i) => (
          <Box
            key={`column-${String(i)}`}
            width={{ md: '50%', _: '100%' }}
            mb="-10px"
          >
            {column.map((answer) => (
              <Radio
                key={question + answer}
                id={question + answer}
                label={answer}
                value={answer}
                name={question}
                onChange={handleInputChange}
                checked={input[question] === answer}
              />
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
