import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AppState = {
  theme: 'primary' | 'secondary' | 'recuro';
};

const initialState: AppState = {
  theme: 'primary',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<'primary' | 'secondary' | 'recuro'>) {
      state.theme = action.payload;
    },
  },
});

const { actions, reducer } = appSlice;
export const { setTheme } = actions;
export default reducer;
