import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import authReducer from './authSlice';
import patientReducer from './patientSlice';
import wizardReducer from './wizardSlice';
import memberReducer from './memberSlice';
import adminReducer from './adminSlice';
import adminTablesReducer from './adminTablesSlice';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  patient: patientReducer,
  wizard: wizardReducer,
  member: memberReducer,
  admin: adminReducer,
  adminTables: adminTablesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
