import React from 'react';
import CopyForm, { Field } from 'components/common/CopyForm/CopyForm';
import { InsuranceInformation as InsuranceInfo } from 'types/patient';

type Props = {
  data: InsuranceInfo;
};

function InsuranceInformation({ data }: Props) {
  const fields: Field[] = [
    { id: 1, label: 'Insurance provider', value: data.insurance_plan },
    { id: 2, label: 'Insurance company name', value: data.other_insurance },
    {
      id: 3,
      label: 'Primary Cardholder’s Name',
      value: data.primary_cardholder_name,
    },
    { id: 4, label: 'Rx Group', value: data.rx_group },
    { id: 5, label: 'Member ID', value: data.member_id },
    { id: 6, label: 'BIN', value: data.bin },
    { id: 7, label: 'PCN', value: data.pcn },
  ];
  return (
    <div>
      <CopyForm fields={fields} />
    </div>
  );
}

export default InsuranceInformation;
