import React, { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import useBreakpoint from 'utils/customhooks/useBreakpoint';
import { breakpointValues as breakpoints } from 'styles/system/breakpoints';
import MobileNavbar from './components/MobileNavbar';
import DesktopNavbar from './components/DesktopNavbar';

export type Props = {
  children: React.ReactNode;
};

function Navbar({ children }: Props) {
  const currentBreakpoint = useBreakpoint();
  const NavbarComponent =
    currentBreakpoint <= breakpoints.md ? MobileNavbar : DesktopNavbar;
  const [scrolled, setScrolled] = useState(false);
  useScrollPosition(({ currPos }) => {
    const currentScroll = currPos.y < 0;
    if (currentScroll !== scrolled) {
      setScrolled(currentScroll);
    }
  });
  return <NavbarComponent scrolled={scrolled}>{children}</NavbarComponent>;
}

export default Navbar;
