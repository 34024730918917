import moment, { Moment } from 'moment';

export const isValidBirthdate = (inputDate: string | undefined): boolean => {
  if (!inputDate) {
    return false;
  }
  if (!moment(inputDate, 'MM/DD/YYYY', true).isValid()) {
    return false;
  }
  const date = moment(inputDate);
  const maxDate = new Date();
  const minDate = new Date('Jan 1, 1900');
  if (date.isBefore(minDate) || date.isAfter(maxDate)) {
    return false;
  }
  return true;
};

export const toMMDDYYYY = (date: Date | string | Moment | undefined) => {
  if (!date) {
    return undefined;
  }
  const parsedDate = moment.utc(date);
  return parsedDate.format('MM/DD/YYYY');
};

export const getExpirationTimeInMilli = (expireUTC: string) => {
  const expirationDate = moment.utc(expireUTC);
  const currentDate = moment.utc();
  return expirationDate.diff(currentDate);
};
