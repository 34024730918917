import React from 'react';
import styled from '@emotion/styled';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';

import { Theme, defaultTheme } from 'styles/system';
import BaseButton from './BaseButton';
import { Props } from '../Button';

const LinkButton = ({ loading, disabled, children, ...rest }: Props) => {
  const loaderStyles = css`
    border-color: ${defaultTheme.textColorOnDisabled};
    border-bottom-color: transparent;
    width: 20px;
    height: 20px;
    margin-right: 7px;
    transition: 1s;
  `;
  const isDisabled = loading || disabled;
  return (
    <Wrapper {...rest} disabled={isDisabled}>
      <ClipLoader loading={loading} css={loaderStyles} />
      {children}
    </Wrapper>
  );
};
const Wrapper = styled(BaseButton)<Props, Theme>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: none;
  border-radius: 8px;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: normal;
  padding: 4px 12px;

  &:hover:enabled {
    background: ${({ theme }) => theme.colors.gray[200]};
  }
  &:active:focus {
    background: ${({ theme }) => theme.colors.gray[300]};
  }
  &:disabled {
    color: ${({ theme }) => theme.textColorOnDisabled};
  }
`;

export default LinkButton;
