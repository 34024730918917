import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { breakpointValues as breakpoints } from 'styles/system/breakpoints';

const getBreakpoint = (width: number) => {
  if (width <= breakpoints.xs) {
    return breakpoints.xs;
  }
  if (width > breakpoints.xs && width <= breakpoints.sm) {
    return breakpoints.sm;
  }
  if (width > breakpoints.sm && width <= breakpoints.md) {
    return breakpoints.md;
  }
  if (width > breakpoints.md && width <= breakpoints.lg) {
    return breakpoints.lg;
  }
  return breakpoints.xl;
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() =>
    getBreakpoint(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      return setBreakpoint(getBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakpoint;
};
export default useBreakpoint;
