import React from 'react';
import styled from '@emotion/styled';
import { Paragraph, Text } from 'components/common';

type Props = {
  name: string;
  description: string;
  show: boolean;
};

type StyledProps = {
  show: boolean;
};

const Wrapper = styled.div<StyledProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  height: 101px;
`;

function StepInfo({ name, description, show }: Props) {
  return (
    <Wrapper show={show}>
      <Paragraph weight="semi-bold" mb={0}>
        {name}
      </Paragraph>
      <Text size="sm" color="gray">
        {description}
      </Text>
    </Wrapper>
  );
}

export default StepInfo;
