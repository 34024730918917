import React from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
};

type RowProps = {
  variant?: 'primary' | 'secondary';
  last?: boolean;
};

const Container = styled.div<Props, Theme>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-basis: 100%;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.base};
  margin: 8px;
  text-align: center;
  @media (min-width: 1280px) {
    flex: 1;
  }
`;

const TableItem = styled.div<RowProps, Theme>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  width: 100%;
  min-height: 50px;
`;

const Header = styled(TableItem)`
  background: ${({ variant, theme }) =>
    variant === 'primary'
      ? theme.colors.primary[100]
      : theme.colors.secondary[100]};
  color: ${({ variant, theme }) =>
    variant === 'primary'
      ? theme.colors.primary[500]
      : theme.colors.secondary[500]};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Row = styled(TableItem)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray[800]};
  border-bottom: ${({ last, theme }) =>
    last ? 'none' : `1px solid ${theme.colors.gray[200]}`};
  border-bottom-left-radius: ${({ last }) => (last ? '8px' : 'none')};
  border-bottom-right-radius: ${({ last }) => (last ? '8px' : 'none')};
`;

function Table({ children, variant }: Props) {
  const count = React.Children.count(children);
  return (
    <Container>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as JSX.Element, {
          last: index === count - 1,
          variant,
        })
      )}
    </Container>
  );
}

Table.Header = Header;
Table.Row = Row;

Table.defaultProps = {
  variant: 'primary',
};

export default Table;
