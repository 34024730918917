import React, { useState, useEffect } from 'react';
import { fromPairs, some, isEqual, flow, omit } from 'lodash/fp';
import useInputChange from 'utils/customhooks/useInputChange';
import styled from '@emotion/styled';
import axios from 'axios';
import {
  Alert,
  Box,
  Button,
  Field,
  H3,
  Paragraph,
  Modal,
  ModalContentTemplate,
} from '..';
import SurveyQuestion from './components/SurveyQuestion';

const Wrapper = styled.div`
  text-align: center;
  padding: 25px;

  .survey-button {
    padding: 4px;
  }
`;

const survey = [
  {
    question: '1. How helpful was the staff?',
    answers: [
      'Extremely helpful',
      'Very helpful',
      'Somewhat helpful',
      'Not so helpful',
      'Not helpful at all',
    ],
  },
  {
    question:
      '2. How likely are you to recommend Ridgeway Mail Order Pharmacy to a friend, family, family member or colleague?',
    answers: ['Very likely', 'Not likely', 'Likely', 'Never'],
  },
  {
    question: '3. How did you place your pharmaceutical order today?',
    answers: [
      'Automated telephone refill',
      'Refill App',
      'Mailed in',
      'Technician via phone call',
      'Doctor sent order in',
    ],
  },
  {
    question:
      '4. How would you rate the accuracy of the medications and supplies we gave or sent you?',
    answers: ['Excellent', 'Good', 'Fair', 'Poor', 'Very poor'],
  },
  {
    question: '5. Rate how easy it was to get in touch with us by phone',
    answers: ['Excellent', 'Good', 'Fair', 'Poor', 'Very poor'],
  },
  {
    question:
      '6. Rate how easy it was to get answers to your questions, follow up or help with any concerns you might have',
    answers: ['Excellent', 'Good', 'Fair', 'Poor', 'Very poor'],
  },
  {
    question:
      '7. How would you rate your overall experience with the pharmacy?',
    answers: ['Excellent', 'Good', 'Fair', 'Poor', 'Very poor'],
  },
];

export type Input = {
  [key: string]: string | undefined;
};

const getInitialState = (): Input => {
  return {
    name: '',
    birthdate: '',
    ...fromPairs(survey.map((item) => [item.question, 'Unanswered'])),
  };
};

const isValidForm = (input: Input) => {
  const thereAreUnansweredQuestions = flow(
    omit(['name', 'birthdate']),
    some(isEqual('Unanswered'))
  )(input);

  return !thereAreUnansweredQuestions;
};

export default function SatisfactionSurvey() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [isFetching, setFetching] = useState(false);

  const [input, handleInputChange, setInput] = useInputChange(
    getInitialState()
  );

  useEffect(() => {
    if (isValidForm(input)) {
      setAlertVisible(false);
    }
  }, [input]);

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setAlertVisible(false);

    if (isValidForm(input)) {
      setFetching(true);
      axios
        .post('/v1/util/satisfaction-survey', { survey: JSON.stringify(input) })
        .then(() => {
          setModalOpen(false);
          setSuccessModalOpen(true);
          setInput(getInitialState());
        })
        .catch(() => {
          // on error
        })
        .finally(() => {
          setFetching(false);
        });
    } else {
      setAlertVisible(true);
    }
  };

  return (
    <Wrapper>
      <Paragraph>
        Tell us how we did by taking this
        <Button
          className="survey-button"
          variant="link"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          brief survey
        </Button>
      </Paragraph>
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        blockScroll={false}
        center
      >
        <Box padding={{ md: '30px 30px 15px 30px', _: '30px 15px 15px 15px' }}>
          <H3 mb="30px">
            Ridgeway Mail Order Pharmacy Customer Satisfaction Survey
          </H3>
          <form onSubmit={handleSubmit}>
            <Box pb="30px" width={{ md: '50%', _: '100%' }}>
              <Field
                id="name"
                name="name"
                label="Full name (Optional)"
                type="text"
                value={input.name}
                onChange={handleInputChange}
              />
              <Field
                id="birthdate"
                name="birthdate"
                label="Birthdate (Optional)"
                value={input.birthdate}
                onChange={handleInputChange}
                placeholder="MM/DD/YYYY"
              />
            </Box>
            {survey.map((item) => (
              <SurveyQuestion
                key={item.question}
                question={item.question}
                answers={item.answers}
                input={input}
                handleInputChange={handleInputChange}
              />
            ))}
            {alertVisible && (
              <Alert
                type="error"
                title="One or more questions have no answer"
              />
            )}
            <Box display="flex" justifyContent="center" mt="20px">
              <Button
                width={{ sm: 'auto', xm: '100%' }}
                type="submit"
                loading={isFetching}
              >
                Send
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Modal
        open={isSuccessModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        blockScroll={false}
        center
      >
        <ModalContentTemplate
          title="Thank you for your feedback"
          type="success"
        >
          <Paragraph>
            Thank you for taking the time to complete this survey. Your answers
            will allow us to better serve you in the future.
          </Paragraph>
          <Paragraph mt="20px">
            <Button
              variant="outlined"
              onClick={() => setSuccessModalOpen(false)}
            >
              Close
            </Button>
          </Paragraph>
        </ModalContentTemplate>
      </Modal>
    </Wrapper>
  );
}
