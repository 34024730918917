import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = {
  mobile?: boolean;
};

const LinkIndicator = styled.div<Props, Theme>`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary[400]};
  margin-top: ${({ mobile }) => (mobile ? 0 : '4px')};
  margin-left: ${({ mobile }) => (mobile ? '-8px' : 0)};
  height: 8px;
  width: 8px;
`;

export default LinkIndicator;
