import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { RootState } from 'redux/rootReducer';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { defaultTheme as theme, recuroTheme } from 'styles/system';
import { WizardProgress } from 'redux/wizardSlice';
import { regularSteps, contraveSteps, recuroSteps } from 'constants/steps';
import Step from './components/Step';
import StepInfo from './components/StepInfo';
import 'react-circular-progressbar/dist/styles.css';

const Wrapper = styled.div`
  .desktop-stepper {
    display: flex;
    @media (max-width: 640px) {
      display: none;
    }
    .indicators {
      width: 36px;
      min-height: 628px;
    }
    .steps-info {
      width: 258px;
      height: 628px;
      margin-left: 16px;
    }
  }
  .mobile-stepper {
    display: flex;
    flex-direction: row;
    @media (min-width: 640px) {
      display: none;
    }
    padding: 10px;
    max-height: 150px;
    justify-content: space-evenly;

    & > div {
      width: 100%;
    }
  }
`;

type StepObject = {
  id: number;
  name: string;
  description: string;
};

const getPosition = (id: number, stepList: StepObject[]): number => {
  return stepList.findIndex((step) => id === step.id) + 1;
};

function Stepper() {
  const { step, progress } = useSelector((state: RootState) => state.wizard);
  const type = useSelector((state: RootState) => state.patient.user.type);
  const colorTheme = useSelector((state: RootState) => state.app.theme);
  const steps =
    type === 'regular'
      ? regularSteps
      : type === 'contrave'
      ? contraveSteps
      : recuroSteps;

  const stepPosition = getPosition(step, steps);
  const stepProgress = `${stepPosition} of ${steps.length}`;
  const mobileStepperProps =
    progress === WizardProgress.finished
      ? { value: 100, text: '100%' }
      : {
          value: (stepPosition / steps.length) * 100,
          text: stepProgress,
        };

  return (
    <Wrapper>
      <div className="desktop-stepper">
        <div className="indicators">
          {steps.map(({ id }) => (
            <Step
              key={id}
              stepNumber={id}
              currentStep={step}
              lastStep={id === steps.length}
              show
              wizardProgress={progress}
            />
          ))}
        </div>
        <div className="steps-info">
          {steps.map(({ id, name, description }) => (
            <StepInfo key={id} name={name} description={description} show />
          ))}
        </div>
      </div>
      <div className="mobile-stepper">
        <CircularProgressbar
          {...mobileStepperProps}
          styles={buildStyles({
            textColor: `${
              colorTheme !== 'recuro'
                ? theme.colors.primary[500]
                : recuroTheme.colors.primary[500]
            }`,
            pathColor: `${
              colorTheme !== 'recuro'
                ? theme.colors.primary[500]
                : recuroTheme.colors.primary[500]
            }`,
          })}
        />
        {progress !== WizardProgress.finished && step > 0 && (
          <StepInfo
            key={steps[step - 1].id}
            name={steps[step - 1].name}
            description={steps[step - 1].description}
            show
          />
        )}
      </div>
    </Wrapper>
  );
}

export default Stepper;
