import { CSSProperties } from 'react';
import { system } from 'styled-system';

/* styled-system purposely does not support the css property for text-decoration
https://github.com/styled-system/styled-system/issues/195
*/
export type TextDecorationProps = Pick<CSSProperties, 'textDecoration'>;

export const textDecoration = system({
  textDecoration: {
    property: 'textDecoration',
    defaultScale: ['none', 'underline'],
  },
});
