import { reduce, isNil } from 'lodash';
import { flow, split, map, first, isEmpty, join, upperCase } from 'lodash/fp';
import { PersonalInformation, PaymentInformation } from 'types/patient';
import { defaultTheme } from 'styles/system';
import { toMMDDYYYY } from 'utils/date';
import { poBox } from './regex';

export const getInitials = (text: string) => {
  if (isEmpty(text)) {
    return '';
  }

  return flow(
    split(/\s/), // matches ALL spaces
    map(first),
    join(''),
    upperCase
  )(text);
};

export const createURLQueryParams = (object: Object) => {
  const params = reduce(
    object,
    (queryParams, value, key) => {
      return `${queryParams}&${key}=${value}`;
    },
    ''
  );

  return params.substring(1);
};

export const getFullName = (personalInfo: PersonalInformation) => {
  if (isEmpty(personalInfo)) {
    return '';
  }
  return `${personalInfo.first_name || ''} ${personalInfo.last_name || ''}`;
};

export const getNameInitials = (personalInfo: PersonalInformation) => {
  if (isEmpty(personalInfo)) {
    return '';
  }
  return `${personalInfo.first_name?.charAt(0) || ''}${
    personalInfo.last_name?.charAt(0) || ''
  }`;
};

export const isPOBox = (address: string = '') => {
  return poBox.test(address);
};

type Subtitle = {
  text: string;
  color: 'gray' | 'warning' | string;
};

export const getAccordionSubtitle = (
  isProcessed?: boolean,
  lastUpdated?: string | undefined
): Subtitle => {
  if (isNil(isProcessed)) {
    return { text: '', color: 'gray' };
  }
  if (isProcessed) {
    return { text: `Last update ${toMMDDYYYY(lastUpdated)}`, color: 'gray' };
  }
  return {
    text: 'Latest change request is being processed',
    color: defaultTheme.colors.accent[700],
  };
};

export const getCardMask = (info: PaymentInformation) => {
  let last4 = '';
  let exp = '';
  if (!isEmpty(info)) {
    last4 = info.credit_card_number?.slice(-4) || '';
    exp = `${info['credit_card_expiration[month]']}/${info['credit_card_expiration[year]']}`;
  }
  return { last4, exp };
};
