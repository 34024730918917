import React, { ButtonHTMLAttributes } from 'react';
import LinkButton from './LinkButton';
import LinkContainer from './LinkContainer';
import LinkIndicator from './LinkIndicator';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
  first?: boolean;
  last?: boolean;
  mobile?: boolean;
  closeModal?: () => void;
};

function Link({
  active,
  first,
  last,
  mobile,
  closeModal,
  onClick,
  ...rest
}: Props) {
  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    closeModal?.();
  };
  return (
    <LinkContainer first={first} last={last} mobile={mobile}>
      <LinkButton active={active} onClick={onClickHandler} {...rest} />
      {active && <LinkIndicator mobile={mobile} />}
    </LinkContainer>
  );
}

Link.defaultProps = {
  active: false,
  first: false,
  last: false,
  mobile: false,
  closeModal: () => {},
};

export default Link;
