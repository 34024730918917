import React from 'react';
import Text, { Props as TextProps } from '../Text/Text';

export type Props = TextProps & {
  children: React.ReactNode;
};

function H4({ children, ...rest }: Props) {
  return (
    <Text as="h4" size="sm" mb="5px" {...rest}>
      {children}
    </Text>
  );
}

export default H4;
