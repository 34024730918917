import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  strokeWidth?: number;
};

function CloseCircle({ fill, strokeWidth, ...rest }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...rest}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

CloseCircle.defaultProps = {
  width: 18,
  height: 16,
  fill: 'none',
  strokeWidth: 2,
};

export default CloseCircle;
