import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Text } from 'components/common';
import { Theme } from 'styles/system';

type TriggerSiblingProps = {
  title?: ReactNode;
  subtitle?: string;
  subtitleColor?: 'gray' | 'warning' | string;
  position?: 'first' | 'last' | 'middle';
  background?: 'default' | 'white' | 'gray';
};

const Container = styled.div<Omit<TriggerSiblingProps, 'title'>, Theme>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ background, theme }) =>
    background === 'gray'
      ? theme.colors.gray[50]
      : background === 'white'
      ? theme.colors.white
      : 'initial'};
  color: ${({ theme }) => theme.colors.gray[800]};
  padding: 16px;
  border-radius: ${({ position }) =>
    position === 'first'
      ? '8px 8px 0 0 '
      : position === 'last'
      ? '0 0 8px 8px'
      : '0'};
  .header {
    display: block;
  }
`;

function TriggerSibling({
  background,
  position,
  title,
  subtitle,
  subtitleColor,
}: TriggerSiblingProps) {
  return (
    <Container background={background} position={position}>
      <div className="header">
        <Text as="p" weight="semi-bold" size="xl">
          {title}
        </Text>
        <Text as="p" color={subtitleColor}>
          {subtitle}
        </Text>
      </div>
    </Container>
  );
}

TriggerSibling.defaultProps = {
  title: '',
  subtitle: '',
  subtitleColor: 'gray',
  position: 'middle',
  background: 'default',
};

export default TriggerSibling;
