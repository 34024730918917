import React from 'react';
import CopyForm, { Field } from 'components/common/CopyForm/CopyForm';
import { HealthInformation as HealthInfo } from 'types/patient';
import { getTextFromBool } from './utils';

type Props = {
  data: HealthInfo;
};

function HealthInformation({ data }: Props) {
  const fields: Field[] = [
    {
      id: 1,
      label: 'Health conditions',
      value: data.health_conditions,
      type: 'textarea',
    },
    {
      id: 2,
      label: 'Drug allergies?',
      value: getTextFromBool(data.drug_allergies_bool),
    },
    {
      id: 3,
      label: 'Drug allergies',
      value: data.drug_allergies,
      type: 'textarea',
    },
    { id: 4, label: 'Medications', value: data.medications, type: 'textarea' },
    {
      id: 5,
      label: 'Seizures?',
      value: getTextFromBool(data.seizures_bool),
    },
    {
      id: 6,
      label: 'Easy open caps?',
      value: getTextFromBool(data.easy_open_cap_bool),
    },
  ];
  return (
    <div>
      <CopyForm fields={fields} />
    </div>
  );
}

export default HealthInformation;
