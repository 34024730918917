// primary
export const chill = {
  50: '#f1fcfe',
  100: '#ebf8ff',
  200: '#a2eaf6',
  300: '#54d9ee',
  400: '#16bbd5',
  500: '#0e7a8b',
  600: '#0c6674',
  700: '#09515d',
  800: '#073d45',
  900: '#05292e',
};

// secondary
export const chambray = {
  50: '#f4f6fa',
  100: '#f0f3f8',
  200: '#bfcbe3',
  300: '#8ea2cd',
  400: '#5976b5',
  500: '#3c5386',
  600: '#2f426a',
  700: '#23304e',
  800: '#161f31',
  900: '#090d15',
};

// accent
export const romantic = {
  50: '#fff8f5',
  100: '#fff1eb',
  200: '#ffeae0',
  300: '#ffe0d1',
  400: '#ffd9c7',
  500: '#ffceb7',
  600: '#ff8c57',
  700: '#fa4f00',
  800: '#993000',
  900: '#3d1300',
};

// error
export const red = {
  50: '#FFF3F3',
  100: '#fed0d0',
  200: '#feb4b4',
  500: '#fc4343',
  600: '#e33c3c',
  700: '#c53030',
};

// success
export const green = {
  100: '#feeeed',
  500: '#15dd7d',
  600: '#13c771',
};

// warning
export const yellow = {
  50: '#fff8de',
  100: '#fff3c7',
  500: '#ffcd1d',
  600: '#e6b91a',
  700: '#b39013',
};

export const black = {
  light: '#1c1c1e',
};

export const gray = {
  50: '#f9f9fb',
  100: '#f4f5f7',
  200: '#e5e7eb',
  300: '#d2d6dc',
  400: '#9fa6b2',
  500: '#6b7280',
  600: '#4b5563',
  700: '#374151',
  800: '#252f3f',
  900: '#161e2e',
};

export const white = '#ffffff';

export const purple = {
  50: '#c8c4ef',
  100: '#aea8e3',
  200: '#968fd4',
  300: '#7f78c4',
  400: '#665db8',
  500: '#5d54a7',
  600: '#5a5391',
  700: '#55517c',
  800: '#242459',
  900: '#494856',
};
