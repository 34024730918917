import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Theme, typeScale } from 'styles/system';

type Props = HTMLAttributes<HTMLElement> & {
  disabled: boolean;
  status?: 'valid' | 'invalid';
};

const Helper = styled.small<Props, Theme>`
  display: flex;
  align-items: center;
  color: ${({ disabled = false, theme, status }) =>
    disabled
      ? theme.textColorDisabled
      : status === 'invalid'
      ? theme.colors.error[500]
      : theme.colors.gray[700]};
  font-size: ${typeScale.xs};
  font-weight: 400;
  line-height: 20px;
  padding-top: 4px;

  & .icon {
    margin-right: 10px;
  }
`;

export default Helper;
