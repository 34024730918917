import React from 'react';
import { Global, css } from '@emotion/core';
import { normalize } from 'polished';
import DMSerifDisplay from './fonts/DMSerifDisplay-Regular.ttf';
import Inter from './fonts/Inter-Regular.ttf';
import InterSemiBold from './fonts/Inter-SemiBold.ttf';

const styles = css`
  ${normalize()}
  @font-face {
    font-family: 'DM Serif Display';
    src: url(${DMSerifDisplay}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${Inter}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  /* Prevent unwanted zoom in on iPhones: https://stackoverflow.com/a/6394497/4191598 */
  select,
  textarea,
  input,
  input[type='number'] {
    font-size: 16px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    margin: 0;
  }

  p {
    margin: 0;
  }

  /** Accessibility **/
  a:focus,
  button:focus {
    outline-color: black;
    outline-style: dotted;
    outline-width: 1px;
  }
`;

function GlobalStyle() {
  return <Global styles={styles} />;
}

export default GlobalStyle;
