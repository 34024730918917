import React from 'react';
import Text, { Props as TextProps } from '../Text/Text';

export type Props = TextProps & {
  children: React.ReactNode;
};

function H3({ children, ...rest }: Props) {
  return (
    <Text as="h3" size="xl" mb="8px" weight="semi-bold" {...rest}>
      {children}
    </Text>
  );
}

H3.defaultProps = {
  font: 'primary',
};

export default H3;
