import styled from '@emotion/styled';
import React, { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import { space, SpaceProps } from 'styled-system';
import { Theme } from 'styles/system';
import {
  FieldGroup,
  Helper,
  HelperContainer,
  Input,
  InputContainer,
  InputPrepend,
  Label,
} from './components';

export type Props = InputHTMLAttributes<HTMLInputElement> &
  SpaceProps &
  InputHTMLAttributes<HTMLTextAreaElement> & {
    disabled?: boolean;
    helper?: string;
    id: string;
    label: string;
    maxLength?: number;
    multiLine?: boolean;
    name: string;
    onChange?:
      | ((event: ChangeEvent<HTMLInputElement>) => void)
      | ((event: ChangeEvent<HTMLTextAreaElement>) => void);
    placeholder?: string;
    value?: string;
    status?: 'valid' | 'invalid';
    icon?: React.ReactNode;
    decorationVariant?: 'default' | 'inverse';
  };

type StyledProps = SpaceProps & {};

const Wrapper = styled.div<StyledProps, Theme>`
  ${space}
`;

function Field({
  disabled = false,
  helper = '',
  id,
  label,
  maxLength,
  multiLine,
  name,
  type = 'text',
  value = '',
  status,
  icon,
  decorationVariant,
  ...rest
}: Props) {
  const [focused, setFocused] = useState(false);
  const shouldShowHelper = helper !== '';
  const hasPrepend = !multiLine && (type === 'tel' || !!icon);
  return (
    <Wrapper {...rest}>
      <FieldGroup>
        <Label disabled={disabled} htmlFor={id}>
          {label}
        </Label>
        <InputContainer hasPrepend={hasPrepend}>
          {hasPrepend && (
            <InputPrepend
              focused={focused}
              status={status}
              variant={decorationVariant}
            >
              {type === 'tel' ? '+1' : icon}
            </InputPrepend>
          )}
          <Input
            {...rest}
            aria-describedby={shouldShowHelper ? `${id}Help` : undefined}
            aria-disabled={disabled}
            disabled={disabled}
            id={id}
            hasPrepend={hasPrepend}
            name={name}
            maxLength={maxLength}
            multiLine={multiLine}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            type={type}
            value={value}
            status={status}
            focused={focused}
          />
        </InputContainer>
        {typeof maxLength === 'number' && (
          <HelperContainer>
            <Helper disabled={disabled}>{`Max ${maxLength} characters`}</Helper>
            <Helper disabled={disabled}>
              {`${maxLength - value.length}/${maxLength}`}
            </Helper>
          </HelperContainer>
        )}
        <HelperContainer>
          {shouldShowHelper && (
            <Helper id={`${id}Help`} disabled={disabled} status={status}>
              {helper}
            </Helper>
          )}
        </HelperContainer>
      </FieldGroup>
    </Wrapper>
  );
}

export default Field;
