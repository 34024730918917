import React from 'react';
import styled from '@emotion/styled';
import { WizardProgress } from 'redux/wizardSlice';
import StepIndicator from './StepIndicator';
import Conector from './Conector';

type Props = {
  stepNumber: number;
  currentStep: number;
  lastStep?: boolean;
  show: boolean;
  wizardProgress: WizardProgress;
};

type StyledProps = {
  show: boolean;
};

const Wrapper = styled.div<StyledProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

function Step({
  currentStep,
  lastStep,
  stepNumber,
  show,
  wizardProgress,
}: Props) {
  const status =
    wizardProgress === WizardProgress.finished
      ? 'finished'
      : stepNumber < currentStep
      ? 'finished'
      : stepNumber === currentStep
      ? 'active'
      : 'pending';
  const active = stepNumber <= currentStep;
  return (
    <Wrapper show={show}>
      <StepIndicator status={status} />
      {!lastStep && <Conector active={active} />}
    </Wrapper>
  );
}

Step.defaultProps = {
  lastStep: false,
};

export default Step;
