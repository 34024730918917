import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { textDecoration } from 'styles/system';

const RouterLink = styled(Link)`
  text-decoration: none;
  &:link,
  &:active,
  &:visited {
    color: inherit;
  }
  ${textDecoration}
`;

export default RouterLink;
