import { LabelHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Theme, typeScale } from 'styles/system';

type Props = LabelHTMLAttributes<HTMLLabelElement> & { disabled: boolean };

const Label = styled.label<Props, Theme>`
  color: ${({ disabled = false, theme }) =>
    disabled ? theme.textColorDisabled : theme.textColor};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: ${typeScale.sm};
  font-weight: 400;
  line-height: 22px;
  margin: 5px 0 0 0;
`;

export default Label;
