import { useHistory } from 'react-router-dom';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

const GoogleTagManagerPageViewRecorder = () => {
  const history = useHistory();
  history.listen((h) => {
    window.dataLayer.push({
      event: 'pageview',
      url: h.pathname,
    });
  });

  return null;
};

export default GoogleTagManagerPageViewRecorder;
