import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import ChevronDown from 'assets/icons/ChevronDown';
import ChevronUp from 'assets/icons/ChevronUp';
import { Box, Text } from 'components/common';
import { Theme } from 'styles/system';

type TriggerProps = {
  title?: ReactNode;
  open?: boolean;
};

const Container = styled.div<Omit<TriggerProps, 'title'>, Theme>`
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
  color: ${({ open, theme }) =>
    open ? theme.colors.primary[500] : theme.colors.gray[700]};
`;

function Trigger({ open, title }: TriggerProps) {
  return (
    <Container open={open} aria-expanded={open} role="button" tabIndex={0}>
      <Text as="p" weight="semi-bold" color={open ? 'primary-dark' : 'default'}>
        {title}
      </Text>
      <Box minWidth={18}>
        {open ? <ChevronUp strokeWidth={3} /> : <ChevronDown strokeWidth={3} />}
      </Box>
    </Container>
  );
}

Trigger.defaultProps = {
  title: '',
  open: false,
};

export default Trigger;
