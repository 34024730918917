import React from 'react';

type Props = {
  width?: number;
  height?: number;
  strokeWidth?: number;
};

function Menu({ strokeWidth, ...rest }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...rest}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  );
}

Menu.defaultProps = {
  width: 16,
  height: 16,
  strokeWidth: 2,
};

export default Menu;
