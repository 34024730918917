import React from 'react';
import { startCase } from 'lodash';
import CopyForm, { Field } from 'components/common/CopyForm/CopyForm';
import { PersonalInformation as PersonalInfo } from 'types/patient';
import { toMMDDYYYY } from 'utils/date';

type Props = {
  data: PersonalInfo;
};

function PersonalInformation({ data }: Props) {
  const gender = data.gender ? startCase(data.gender) : data.gender;
  const fields: Field[] = [
    { id: 1, label: 'First name', value: data.first_name },
    { id: 2, label: 'Middle name', value: data.middle_name },
    { id: 3, label: 'Last name', value: data.last_name },
    { id: 4, label: 'Gender', value: gender },
    { id: 5, label: 'Birthdate', value: toMMDDYYYY(data.birthdate) },
  ];
  return (
    <div>
      <CopyForm fields={fields} />
    </div>
  );
}

export default PersonalInformation;
