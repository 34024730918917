import React from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = {
  active?: boolean;
};

const Wrapper = styled.div<Props, Theme>`
  width: 2px;
  height: 65px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary[500] : theme.colors.gray[400]};
`;

function Conector({ active }: Props) {
  return <Wrapper active={active} />;
}

Conector.defaultProps = {
  active: false,
};

export default Conector;
