import React from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { Theme } from 'styles/system';

export type Props = React.ComponentProps<typeof Select> & {
  variant?: 'valid' | 'invalid';
};

const StyledSelect = styled(Select)<Props, Theme>`
  margin-top: 4px;

  & .dropdown__control {
    background: #ffffff;
    border: ${({ theme, variant }) =>
      `1px solid ${
        variant === 'valid'
          ? theme.colors.success[600]
          : variant === 'invalid'
          ? theme.colors.error[500]
          : theme.colors.gray[400]
      }`};
    border-radius: 8px;
    height: 50px;
    min-width: 280px;
  }

  & .dropdown__control:hover {
    border: ${({ theme, variant }) =>
      `1px solid ${
        variant === 'valid'
          ? theme.colors.success[600]
          : variant === 'invalid'
          ? theme.colors.error[500]
          : theme.colors.gray[400]
      }`};
  }

  & .dropdown__value-container {
    padding-left: 20px;
  }

  & .dropdown__placeholder {
    margin: 0;
    color: ${({ theme }) => theme.colors.gray[400]};
  }

  & .dropdown__indicator {
    color: ${({ theme }) => theme.colors.gray[800]};
    padding-right: 20px;
  }

  & .dropdown__indicator-separator {
    display: none;
  }

  & .dropdown__option {
    color: ${({ theme }) => theme.colors.gray[800]};
  }

  & .dropdown__option--is-focused,
  .dropdown__option:focus {
    background-color: ${({ theme }) => theme.colors.primary[100]};
  }

  & .dropdown__option--is-selected {
    background-color: ${({ theme }) => theme.colors.primary[200]};
  }

  & .dropdown__control--is-focused {
    border: ${({ theme }) => `1px solid ${theme.primaryColor}`};
    box-shadow: ${({ theme }) => `0 0 0 0.5px ${theme.primaryColor}`};
    -webkit-box-shadow: ${({ theme }) => `0 0 0 0.5px ${theme.primaryColor}`};
    -moz-box-shadow: ${({ theme }) => `0 0 0 0.5px ${theme.primaryColor}`};
  }
`;

export default StyledSelect;
