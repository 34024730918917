import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

const FieldGroup = styled.div<HTMLAttributes<HTMLDivElement>, Theme>`
  display: flex;
  flex-direction: column;
`;

export default FieldGroup;
