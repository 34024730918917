import React from 'react';
import { Paragraph, RouterLink, Text } from 'components/common';
import routes from 'constants/routes';
import ModalContentTemplate from './ModalContentTemplate';

function ModalContentNeedsPasswordReset() {
  return (
    <ModalContentTemplate type="warning" title="Reset password required.">
      <Paragraph>
        We have recently improved security on all accounts as part of our
        commitment to our patients. Unfortunately, this means you need to update
        your password by{' '}
        <RouterLink to={routes.forgotPassword.path}>
          <Text color="primary-dark">clicking this link.</Text>
        </RouterLink>
      </Paragraph>
      <Paragraph mt={30}>
        Have questions or need help? <br />
        Please call{' '}
        <a style={{ textDecoration: 'none' }} href="tel:18006303214">
          <Text color="primary-dark">1 800 630 3214</Text>
        </a>{' '}
        and we would be happy to assist you.
      </Paragraph>
    </ModalContentTemplate>
  );
}

export default ModalContentNeedsPasswordReset;
