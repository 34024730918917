import { notification } from 'antd';
import 'antd/lib/notification/style/css';

type NotificationType = 'warning' | 'success' | 'info' | 'error';

function Notification(
  type: NotificationType,
  title: string,
  description: string
) {
  notification[type]({
    message: title,
    description,
  });
}

export default Notification;
