import React from 'react';
import { Helper, HelperContainer } from '../Field/components';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  helper: string;
  status?: 'valid' | 'invalid';
  id: string;
};

FieldGroup.defaultProps = {
  disabled: false,
  status: undefined,
};

function FieldGroup({ children, helper, disabled = false, id, status }: Props) {
  const shouldShowHelper = helper !== '';
  return (
    <div id={id}>
      {children}
      <HelperContainer>
        {shouldShowHelper && (
          <Helper disabled={disabled} status={status}>
            {helper}
          </Helper>
        )}
      </HelperContainer>
    </div>
  );
}

export default FieldGroup;
