import React from 'react';
import CopyForm, { Field } from 'components/common/CopyForm/CopyForm';
import {
  DBFields,
  PrescriptionInformation as PrescriptionInfo,
  UserFk,
} from 'types/patient';
import { Button } from 'components/common';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';

type Props = {
  data: PrescriptionInfo & DBFields & UserFk;
};

function PrescriptionInformation({ data }: Props) {
  const patient = useSelector((state: RootState) => state.admin.patient);
  const fields: Field[] = [
    { id: 1, label: 'Transfer method', value: data.transfer_method },
    {
      id: 2,
      label: 'Drug name',
      value: data.drug_name,
    },
    { id: 3, label: 'Physician name', value: data.physician_name },
    {
      id: 4,
      label: 'Physician phone',
      value: data?.physician_phone
        ?.replace(/^\+?1|[()-]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
    },
    {
      id: 5,
      label: 'Physician fax',
      value: data?.physician_fax_number
        ?.replace(/^\+?1|[()-]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
    },
    { id: 6, label: 'Pharmacy name', value: data.pharmacy_name },
    {
      id: 7,
      label: 'Pharmacy phone',
      value: data?.pharmacy_phone
        ?.replace(/^\+?1|[()-]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
    },
    { id: 8, label: 'Pharmacy RX number', value: data.rx_number },
  ];

  const handlePrintButton = () => {
    let urlEndpoint = '';
    let type = '';
    if (data.transfer_method === 'Doctor') {
      urlEndpoint = `/v1/patients/${data.user_id}/${data.id}/print/fax`;
      type = 'fax';
    } else {
      urlEndpoint = `/v1/patients/${data.user_id}/${data.id}/print/request`;
      type = 'request';
    }
    axios({
      url: urlEndpoint,
      method: 'GET',
      responseType: 'arraybuffer',
    }).then((response) => {
      const pdfString = Buffer.from(response.data, 'base64');
      const url = `data:application/pdf;base64,${pdfString}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.physician_name} ${type} form.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}
      >
        {(data.transfer_method === 'Doctor' ||
          data.transfer_method === 'Pharmacy') &&
          patient?.user.type === 'contrave' && (
            <Button onClick={handlePrintButton}>Print fax form</Button>
          )}
      </div>
      <CopyForm fields={fields} />
      <br />
    </div>
  );
}

export default PrescriptionInformation;
