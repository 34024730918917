import React, { ReactNode } from 'react';
import Collapsible from 'react-collapsible';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';
import Trigger from './components/Trigger';
import TriggerSibling from './components/TriggerSibling';

export type Props = {
  title: ReactNode;
  subtitle?: string;
  subtitleColor?: 'gray' | 'warning' | string;
  children: React.ReactNode;
  variant?: 'default' | 'rounded';
  position?: 'first' | 'last' | 'middle';
  background?: 'default' | 'white' | 'gray';
  open?: boolean;
  collapsibleArea?: 'full-header' | 'arrows-only';
};

type WrapperProps = {};

const Wrapper = styled.div<WrapperProps, Theme>`
  .Collapsible {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
    position: relative;
    .Collapsible__trigger {
      cursor: pointer;
    }
  }
`;

Accordion.defaultProps = {
  variant: 'default',
  subtitle: '',
  subtitleColor: 'gray',
  position: 'middle',
  background: 'default',
  open: false,
};

function Accordion(props: Props) {
  const { children, collapsibleArea, open, variant } = props;
  const TriggerSiblingComponent =
    variant === 'rounded' && collapsibleArea === 'arrows-only'
      ? () => <TriggerSibling {...props} />
      : undefined;
  return (
    <Wrapper>
      <Collapsible
        trigger={<Trigger {...props} />}
        triggerWhenOpen={<Trigger {...props} open />}
        triggerSibling={TriggerSiblingComponent}
        contentHiddenWhenClosed
        open={open}
      >
        {children}
      </Collapsible>
    </Wrapper>
  );
}

export default Accordion;
