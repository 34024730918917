import React from 'react';
import Text, { Props as TextProps } from '../Text/Text';

export type Props = TextProps & {
  children: React.ReactNode;
};

function H1({ children, ...rest }: Props) {
  return (
    <Text as="h1" size="3xl" mb="8px" {...rest}>
      {children}
    </Text>
  );
}

H1.defaultProps = {
  font: 'secondary',
};

export default H1;
