import React from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: React.ReactNode;
  href?: string;
  variant?: 'primary' | 'secondary';
};

const Anchor = styled.a<Props, Theme>`
  color: ${({ theme, variant }) =>
    variant === 'primary'
      ? theme.colors.primary[500]
      : theme.colors.primary[400]};
  cursor: pointer;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
  text-underline-position: under;
`;

function Link({ children, href, variant }: Props) {
  return (
    <Anchor href={href} target="_blank" variant={variant}>
      {children}
    </Anchor>
  );
}

Link.defaultProps = {
  variant: 'primary',
  href: '',
};

export default Link;
