import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DependentInformation } from 'types/patient';

type FormState = {
  id: number;
  data: {
    isValid: boolean;
    data?: DependentInformation;
  };
};

export type DependentForms = {
  [key: number]: {
    isValid: boolean;
    data?: DependentInformation;
  };
};

export enum WizardProgress {
  none = 'none',
  inProgress = 'in-progress',
  finished = 'finished',
}

type WizardState = {
  step: number;
  progress: WizardProgress;
  dependentForms: DependentForms;
};

const initialState: WizardState = {
  step: 0,
  progress: WizardProgress.none,
  dependentForms: {},
};

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      progress: WizardProgress.finished,
    }),
    enableWizard(state) {
      state.progress = WizardProgress.inProgress;
      state.step = 2;
    },
    enableRecuroWizard(state) {
      state.progress = WizardProgress.inProgress;
      state.step = 3;
    },
    nextStep(state, action: PayloadAction<number | undefined>) {
      state.step += action.payload || 1;
    },
    previousStep(state, action: PayloadAction<number | undefined>) {
      state.step = Math.max(state.step - (action.payload || 1), 2);
    },
    addDependentForm(state, action: PayloadAction<FormState>) {
      state.dependentForms[action.payload.id] = action.payload.data;
    },
    deleteDependentForm(state, action: PayloadAction<FormState>) {
      delete state.dependentForms[action.payload.id];
    },
    saveDependentForm(state, action: PayloadAction<FormState>) {
      state.dependentForms[action.payload.id] = action.payload.data;
    },
    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload;
    },
  },
});

const { actions, reducer } = wizardSlice;
export const {
  reset,
  addDependentForm,
  deleteDependentForm,
  saveDependentForm,
  nextStep,
  previousStep,
  enableWizard,
  enableRecuroWizard,
  setStep,
} = actions;
export default reducer;
