import React from 'react';
import styled from '@emotion/styled';
import { Theme } from 'styles/system';

type Props = {
  children: React.ReactNode;
  variant?: 'gray' | 'primary';
};

const ListItem = styled.li<Props, Theme>`
  margin-bottom: 16px;
  margin-right: 16px;
  &:before {
    content: '';
    width: ${({ variant }) =>
      variant === 'gray' ? '4px' : variant === 'primary' ? '8px' : '4px'};
    height: ${({ variant }) =>
      variant === 'gray' ? '4px' : variant === 'primary' ? '8px' : '4px'};
    border-radius: 100%;
    background: ${({ theme, variant }) =>
      variant === 'gray'
        ? theme.colors.gray[400]
        : variant === 'primary'
        ? theme.colors.primary[400]
        : theme.colors.gray[400]};
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: ${({ variant }) =>
      variant === 'gray' ? '4px' : variant === 'primary' ? '24px' : '4px'};
  }
`;

function Item({ children, variant }: Props) {
  return <ListItem variant={variant}>{children}</ListItem>;
}

Item.defaultProps = {
  variant: 'gray',
};

export default Item;
